import React from 'react';

import { BarChart,Bar,Legend,ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

function Chart(props) {
  return (
    <article>
      <h5 className="card-title">{props.title}</h5>
      <ResponsiveContainer minWidth={"100%"} minHeight={"80vh"}>
        <BarChart data={props.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </article>
  );
}

export default Chart;
