import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import {registerSW} from './client';
//import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import './app.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

export default React.PureComponent;
export const pureComponentAvailable = true;

ReactDOM.render(<Root/>, document.getElementById('root'));

//serviceWorker.register();
registerSW();
