import React,{	Component	} from 'react';
import { fromJS	} from 'immutable';
import moment from 'moment';
import {	endpoint, toHome, endpointSocketData, endpointSocketScheduled	} from '../../config';

import Hour from '../../components/Hour';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Loading from '../../components/Loading';

import Aside from '../../components/Aside';

import { DayPickerSingleDateController } from 'react-dates';

import TracertContext from '../../context/app-context';

import socketIOClient from "socket.io-client";

class TaskEdit extends Component {

	static contextType = TracertContext;

	state = {
		id: '',
		vehicle: "",
		type: "",
		tasks:[],
		date: moment(),
		end: "",
		isLoaded: false,
		time: {	begin: "08:00",	end: "09:00", added: null	},
		technician: '',
		technicians: fromJS([]),
		selects: fromJS([]),
		observations: '',
		locations: [],
		location: { city: "", address: "" , number:""},
		contacts: { email: "", whatsapp: "" },
		buttonEnable: true,
		scheduled: false,
		socketData: null,
		socketScheduled: null
	}

	handleChangeObservations(observations){ this.setState({	observations	}) }
	handleChangeVehicle(vehicle){ this.setState({	vehicle	}) }
	handleChangeType(type){ this.setState({	type	})}
	handleChangeLocationCity(city){	this.setState({	location:{	...this.state.location,	city	}	})}
	handleChangeLocationNumber(number){	this.setState({	location:{	...this.state.location,	number	}	})}
	handleChangeLocationAddress(address){ this.setState({	location:{	...this.state.location,	address	}})}
	handleChangeContactsEmail(email){	this.setState({	contacts:	{	...this.state.contacts,	email	}})}
	handleChangeContactsWhatsapp(whatsapp){	this.setState({	contacts:	{	...this.state.contacts,	whatsapp	}});}
	
	handleChangeTimeBegin(begin){	this.setState({	time: {	...this.state.time,	begin	}})}
	handleChangeTimeEnd(end){	this.setState({	time: {	...this.state.time,	end	}})}
	handleChangeTimeAdded(added){this.setState({time: {...this.state.time,added}})}
	handleChangeTechnician(technician){	this.setState({	technician	})}
	selectTechnician(technician,e){	this.setState(state => ({	technicians: state.technicians.push(technician)	}))}
	deleteTechnician(i,e){	this.setState(state => ({	technicians: state.technicians.delete(i)	}))}

	handleChangeDate(date){	
		this.context.setFecha(date);
		this.setState({	date });
	}

	handleSelect({technician}){
		this.setState({selects:this.state.selects.push(technician)});
	}

	handleDelete(i,e){
		this.setState({selects:this.state.selects.delete(i)});
	}

	getTechnicians(){
    fetch(`${endpoint}/technicians`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({technician:result[0].name,technicians: result});
        }
      )
  }

	getTimes(){
		let fecha = this.state.date.format("YYYY-MM-DD");
		fetch(`${endpoint}/tasks/times/${fecha}/${this.state.vehicle}`)
				.then(res => res.json())
				.then(
					(result) => {
							if (result.length===0){
								this.setState({
									time: { begin: "08:00", end: "09:00" }
								})
							} else {
								let tiempos = result.map((time) => {
									return moment(time.time.end,'HH:mm');
								})
							let m = moment.max(tiempos);
							let s = m._i.split(':');
							let s2 = `${s[0]}.${s[1]}`;
							let s3 = parseFloat(s2);
							let s4 = (s3+1).toFixed(2);
							let s5 = s4.toString();
							s4 = s5.replace('.',':');
								this.setState({
									time: { begin: m._i, end: s4 }
								 });
							}
					 }
				)
	}

	submitAll(){
		let fecha = this.state.date.format("YYYY-MM-DD");
		let token = localStorage.getItem('token');
		this.setState({buttonEnable:false});
    fetch(`${endpoint}/tasks/all`, {
	    method: 'PUT',
	    headers: {'Content-Type': 'application/json','authorization':`${token}`},
	    body: JSON.stringify({
	      id: this.props.match.params.id,
	      vehicle: this.state.vehicle,
	      time: this.state.time,
	      date: fecha,
	      observations: this.state.observations,
	      type: this.state.type,
	      location: this.state.location,
	      contacts: this.state.contacts,
	      technicians: this.state.selects
	    })
	  })
	  .then(res => res.json())
	  .then(res => {
	    if(res.success===true){
			const day = moment().format("YYYY-MM-DD");
			if(this.state.date.format("YYYY-MM-DD")===day){
				this.state.socketData.emit("initial_data",day);
				this.state.socketScheduled.emit("initial_data_scheduled",this.state.scheduled);
			}
			this.props.history.push(toHome);
	    }
	  })
	}

	getTask(){
		fetch(`${endpoint}/tasks/${this.props.match.params.id}`)
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						id: result._id,
						type: result.type,
						date: moment(result.date),
						vehicle: result.vehicle,
						selects: fromJS(result.technicians),
						observations: result.observations,
						location: result.location,
						contacts: result.contacts,
						time: result.time,
						scheduled: result.scheduled
					});
				}
			).then(()=>this.getVehicles()).then(()=>this.getTechnicians()).then(()=>this.getLocations()).then(()=>this.getTaskTypes())
	}

	getTaskTypes(){
    fetch(`${endpoint}/tasktypes`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({tasks: result,task:result[0].name,isLoaded:true});
        }
      )
  }

	getLocations(){
    fetch(`${endpoint}/locations`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({locations: result});
        }
      ).catch(err => this.setState({error:err}))
  }

	getVehicles(){
    fetch(`${endpoint}/vehicles`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({vehicles: result });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

	componentDidMount() {
	  this.getTask();
	  this.setState({
		  socketData: socketIOClient(endpointSocketData),
		  socketScheduled: socketIOClient(endpointSocketScheduled)
	  });
  }

  componentWillUnmount(){
	  this.state.socketData.close();
	  this.state.socketScheduled.close();
  }

	componentDidUpdate(prevProps,prevState){
		if( prevState.id === '' ) {

		} else {
			if ( this.state.date !== prevState.date ) {
				this.getTimes();
			}
			if ( this.state.vehicle !== prevState.vehicle ) {
				this.getTimes();
			}
		}
	}

	render(){
		const {isLoaded,buttonEnable} = this.state;
		if (!isLoaded){
			return (
				<Loading/>
			);
		}else {
			return(
					<main className="newtask">
						<div className="newtask-aside">
							<Aside/>
						</div>
						<div className="newtask-body">
							<div className="card">

								<div className="card-header">
										<h2>Modificar tarea</h2>
								</div>

								<div className="card-body">
									<div className="form-row mb-2">


										<div className="form-group col-md-6">
											<label>Vehículos</label>
											<select className="form-control" value={this.state.vehicle} onChange={(e)=>this.handleChangeVehicle(e.target.value)}>
												{this.state.vehicles.map((v) => {
													return (<option value={v.name} key={v._id}>{v.name}</option>)
												})}
											</select>
										</div>
										<div className="form-group col-md-6">
											<label>Tipo de tarea</label>
											<select className="form-control" value={this.state.type} onChange={(e)=>this.handleChangeType(e.target.value)}>
												{this.state.tasks.map((v) => {
													return (<option value={v.name} key={v._id}>{v.name}</option>)
												})}
											</select>
										</div>

									</div>
									<div className="form-row mb-2 d-flex justify-content-center" style={{"overflowX":"scroll"}}>

										<DayPickerSingleDateController
												date={this.state.date}
												onDateChange={this.handleChangeDate.bind(this)}
												focused={this.state.focused}
												onFocusChange={({focused}) => this.setState({focused})}
										/>

									</div>
									
									{((this.state.time.begin!=="25:00")&&(this.state.time.end!=="26:00")) ? 
										(<div className="form-row mb-2">
										<Hour	label={"Hora de comienzo"}	time={this.state.time.begin}	onSelectTime={this.handleChangeTimeBegin.bind(this)}	/>
										<Hour	label={"Hora de finalización"}	time={this.state.time.end}	onSelectTime={this.handleChangeTimeEnd.bind(this)}	/>
										</div>)
										:(null)
									}

									

									<div className="form-row mb-2">
										<div className="form-group col-md-6">

											<label>Localidades</label>
											<select className="form-control" value={this.state.location.city} onChange={(e)=>this.handleChangeLocationCity(e.target.value)}>
												{this.state.locations.map((v) => {
													return (<option value={v.name} key={v._id}>{v.name}</option>)
												})}
											</select>
										</div>

										<Input	label={"Calle/s"}	value={this.state.location.address}	handleChange={this.handleChangeLocationAddress.bind(this)}	/>
										<Input	label={"Número"}	value={this.state.location.number}	handleChange={this.handleChangeLocationNumber.bind(this)}	/>
									</div>

									<div className="form-row mb-2 d-flex justify-content-center align-items-end">
										<div className="col-md-6">
											<label>Técnicos a cargo</label>
											<select className="form-control" value={this.state.technician} onChange={(e)=>this.handleChangeTechnician(e.target.value)}>
													{this.state.technicians.map((t,i) => {
														return <option key={i} value={t.name}>{t.name}</option>
													})}
											</select>
										</div>
										<div className="col-md-6 mt-2">
											<button className="btn btn-secondary" onClick={()=>this.handleSelect({technician:this.state.technician})}>Seleccionar</button>
										</div>
										<div className="col-md-12 p-2">
											<ul className="list-group">
												{this.state.selects.map((t,i) => {
													return <li className="list-group-item" key={i}> <p>{t}</p> <button className="btn btn-danger" onClick={(e)=>this.handleDelete(i,e)}>Eliminar</button></li>
												})}
											</ul>
										</div>
									</div>


									<div className="form-row mb-2">
										<Input	label={"Email"}	value={this.state.contacts.email}	handleChange={this.handleChangeContactsEmail.bind(this)}	/>
										<Input placeholder={"2221000000"}	label={"Número de telefono"}	value={this.state.contacts.whatsapp}	handleChange={this.handleChangeContactsWhatsapp.bind(this)}	/>
									</div>
									<TextArea	label={"Observaciones"}	value={this.state.observations}	handleChange={this.handleChangeObservations.bind(this)}	/>
								</div>
								<div className="card-footer">
									{buttonEnable ? (
										<button className="btn btn-primary" onClick={()=>this.submitAll()}>Guardar cambios</button>
									):(
										<button className="btn btn-primary" disabled>Guardar cambios</button>
									)}
								</div>
							</div>
						</div>
					</main>
			);
		}
	}
}

export default TaskEdit;

/*
{this.state.time.added ? (
										<div className="form-row">
											<Hour label={"AÑADIDO"} time={this.state.time.added} onSelectTime={this.handleChangeTimeAdded.bind(this)}/>
										</div>
									):(null)}
*/