import React from 'react';

export default function Observations(props){
    if(props.observations){
        return (
            <div>
                <h5>{props.title}</h5>
                <p>{props.observations}</p>
            </div>
        );
    }
    return null;
}