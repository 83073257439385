import React from 'react';

const ModalSuccess = ({handleClose,show,description,title}) => {
    
    const divStyle = {
        'position': 'fixed',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100vh',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'backgroundColor': '#5E5E5E',
        'opacity': '0.9',
        'zIndex': '1'
    }
    const divStyleHide = {
        display: 'none'
    }
    const showHideClassName = show ? divStyle: divStyleHide
    return (
        <div style={showHideClassName} role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4 className="text-success">{description}</h4>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={handleClose}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalSuccess;