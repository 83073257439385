import React from 'react';

function Input(props){
  return(
    <div className="form-group col-md-6">
      <label>{props.label}</label>
      <input className="form-control" type="text" placeholder={props.placeholder} onChange={(e)=>props.handleChange(e.target.value)} value={props.value}/>
    </div>
  );
}

export default Input;
