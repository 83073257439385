import React,{Component} from 'react';
import { endpointNotifications } from '../../config';

import initial_state from './initial_state';

export default class Message extends Component{
    constructor(){
        super();
        this.state = { ...initial_state }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleChangeAffair = this.handleChangeAffair.bind(this);
    }

    handleChangeMessage(e){ this.setState({message: e.target.value})};
    handleChangeAffair(e){this.setState({affair:e.target.value})};

    handleSubmit(e){
        e.preventDefault();
        let url = `${endpointNotifications}/new-message`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({affair:this.state.affair,message:this.state.message}),
            headers:{ 'Content-Type': 'application/json' }
        }).then(res => res.json())
        .then(result => this.setState({message:'',affair:''}))
        .catch(error => this.setState({ error : error }));
    }

    render(){
        return(
            <div style={{"width":"100%","minHeight":"90vh","display":"flex","justifyContent":"center","alignItems":"center","backgroundColor":"#EEE"}}>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>Asunto</label>
                        <input className="form-control" value={this.state.affair} onChange={this.handleChangeAffair}/>
                    </div>
                    <div className="form-group">
                        <label>Mensaje</label>
                        <input className="form-control" value={this.state.message} onChange={this.handleChangeMessage}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Enviar mensaje</button>
                </form>
            </div>
        );
    }
}