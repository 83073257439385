import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';
import initial_state from './initial_state';

import Square from './Squeare';
import Circle from './Circle';

export default class Zones extends Component {

    state = { ...initial_state };

    setZone(e){ this.setState({ zone: e.target.value }) };
    setColor(e){ this.setState({ color: e.target.value })};

    getZones(){
        fetch(`${endpoint}/zones`)
        .then(res => res.json())
        .then( (result) => this.setState({zones: result,isLoaded:true}))
        .catch(err => this.setState({error:err}))
    }

    componentDidMount(){ this.getZones() }

    handleSubmit(e){
        if ((this.state.zone!=='')&&(this.state.color!=='')){
            let url = `${endpoint}/zones`;
            let token = localStorage.getItem('token');
            var data = { 
                name: this.state.zone.toUpperCase().trim(), 
                created_by: localStorage.getItem('email'),
                color: this.state.color.toUpperCase().trim()
            };
            this.setState({buttonEnable:false});
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers:{ 'Content-Type': 'application/json','authorization': `${token}`  }
            }).then(res => res.json())
            .then(result => {
                if(result.success){
                    this.setState({buttonEnable:true,error:null,zone:'',color:'#4287F5'})
                    this.getZones();
                }else{
                    this.setState({error:result.error,buttonEnable:true});
                }
            })
            .catch(error => console.error('Error:', error));
        } else {    
            this.setState({error:'Debes ingresar un nombre y color'});
        }
    }

    deleteZone(id,e){
        let token = localStorage.getItem('token');
        this.setState({buttonEnable:false});
        fetch(`${endpoint}/zones`, {
        method: 'DELETE',
            headers: {'Content-Type': 'application/json','authorization':`${token}`},
            body: JSON.stringify({id})
        })
        .then(res => res.json())
        .then(res => {
            if(res.success){
                this.setState({buttonEnable:true})
                this.getZones();
            }
        })
    }

    render(){
        const {isLoaded,buttonEnable} = this.state;
        if (!isLoaded){
        return(<Loading/>);
        }else {
        return(
            <main className="newtask">
                <div className="newtask-aside">
                <Aside/>
                </div>
                <div className="newtask-body">

                <div className="card">
                    <div className="card-header">
                        <h2>Lista de zonas</h2>
                    </div>
                    <div className="card-body">

                    <ul className="list-group">
                        {this.state.zones.map( z => {
                        return (
                            <li className="d-flex justify-content-between align-items-center list-group-item" key={z._id}>
                            <p>{z.name}</p>
                            <Circle color={z.color}/>
                            {buttonEnable ? (
                                <button className="btn btn-danger" onClick={(e)=>this.deleteZone(z._id,e)}>Eliminar</button>
                            ):(
                                <button className="btn btn-danger" disabled>Eliminar</button>
                            )}
                            </li>
                        )
                        })}
                    </ul>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="card-header">
                    <h2>Agregar zona</h2>
                    </div>
                    <div className="card-body">
                    <div className="form-group">
                        <div>
                            <label htmlFor="zone">Nueva zona</label>
                            <input id="zone" className="form-control" type="text" value={this.state.zone} onChange={this.setZone.bind(this)}/>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="color">Nuevo color(hexa)</label>
                            <input id="color" className="form-control" 
                            type="text" value={this.state.color} 
                            onChange={this.setColor.bind(this)}
                            placeholder={"#FAFAFA"}
                            />
                            <Square color={this.state.color}/>
                        </div>
                    </div>
                    
                    {this.state.error ? (<div className="alert alert-danger">{this.state.error}</div>) : null }
                    </div>
                    <div className="card-footer">
                    {buttonEnable ? (
                        <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Añadir Zona</button>
                    ):(
                        <button className="btn btn-primary" disabled>Añadir Zona</button>
                    )}
                    </div>
                </div>

                </div>
            </main>
        );
        }

    }
}
