import React,{  Component } from 'react';
import {  endpoint, endpoint3  } from '../../config';
import Loading from '../../components/Loading';
import MsgError from '../../components/MsgError';
import Aside from '../../components/Aside';

import TracertContext from '../../context/app-context';

class ClientEdit extends Component {

  static contextType = TracertContext;

  state = {
      razon: '', abonado: '',
      image: null,
      location: { localidad: '', calle: '', numero: '', barrio: '' , pisodep: '', link: ''},
      contacts: { email: '', telefono: ''},
      localidades: [],
      isLoaded: false,
      error: null,
      errorForm: null,
      success: null,
      enabled: true
  }

  getLocations(){
    this.setState({isLoaded:false})
    fetch(`${endpoint}/locations`)
      .then(res => res.json())
      .then(
        (result) => {
           let localidades = [];
           for (let i=0;i<result.length;i++){
             localidades = localidades.concat(result[i].name);
           }
           this.setState({isLoaded: true,localidades});
        }
      ).catch(err => {
        console.log('eroro',err);
        this.setState({error:err})
      })
  }

  fetchClient(abonado){
     fetch(`${endpoint}/clients/${abonado}`)
      .then(res => res.json())
      .then((result) => {
          result=result[0];
          this.context.setSubscriberName(result.razon);
          this.setState({
            razon: result.razon,
            abonado: result.abonado.toString()||"4",
            image: result.image,
            location: {
              ...this.state.location,
              calle: result.calle,
              numero: (result.numero||""),
              pisodep: result.pisodep,
              barrio: result.barrio,
              localidad: result.localidad,
              link: (result.link||"")
            },
            contacts: {
              ...this.state.contacts,
              email: result.email,
              telefono: result.telefono
            }
          });
        }
      )
      .then(()=>this.getLocations())
      .catch(err => {
        console.log(err);
        //this.setState({error:err})
      })
  }

  componentDidMount(){
    this.fetchClient(this.props.match.params.abonado);
    this.context.setSubscriber(`${this.props.match.params.abonado}`);
  }

  uploadImageFront(){
    this.props.history.push(`/uploadfront/${this.state.abonado}`);
  }

  seeFrontImage(){
    window.open(`${endpoint3}/images/subscribers/${this.state.image}`,'_blank');
  }

  seeLocationLink(){
    window.open(`${this.state.location.link}`);
  }

  handleSubmit(e){
    e.preventDefault();
    let url = `${endpoint}/clients`;
    let {razon,abonado} = this.state;
    razon = razon.toUpperCase().trim();
    abonado = abonado.toUpperCase().trim();
    let location = {
      localidad : this.state.location.localidad.toUpperCase().trim(),
      calle: this.state.location.calle.toUpperCase().trim(),
      numero: (this.state.location.numero||""),
      barrio: this.state.location.barrio.toUpperCase().trim(),
      pisodep: this.state.location.pisodep.toUpperCase().trim(),
      link: this.state.location.link.trim()
    };
    let contacts = {
      email: this.state.contacts.email.trim(),
      telefono: this.state.contacts.telefono.trim()
    }
      fetch(url, {
          method: 'PUT',
          body: JSON.stringify({razon,abonado,location,contacts}),
          headers:{ 'Content-Type': 'application/json','authorization':localStorage.getItem('token')  }
        }).then(res => res.json())
        .then(result => {
          console.log(result);
          if(result.error){
            this.setState({errorForm:result.error,enabled:true});
          }else{
            this.setState({errorForm:null,success:"El abonado fue actualizado exitosamente",enabled:true});
          }
        })
        .catch(error => {
          this.setState({ error : error });
        });
    
   
  }

  handleChangeName(e){ this.setState({ razon: e.target.value }) };
  handleChangeDNI(e){ this.setState({ abonado: e.target.value }) };
  handleChangeLocationCity(e){ this.setState({location: {...this.state.location,localidad:e.target.value} }) };
  handleChangeLocationAddress(e){ this.setState({location: {...this.state.location,calle:e.target.value} }) };
  handleChangeLocationNumero(e){this.setState({location:{...this.state.location,numero:e.target.value}})};
  handleChangeLocationBarrio(e){this.setState({location:{...this.state.location,barrio:e.target.value}})}
  handleChangeLocationPisodep(e){this.setState({location:{...this.state.location,pisodep:e.target.value}})};
  handleChangeLocationLink(e){this.setState({location:{...this.state.location,link:e.target.value}})};
  handleChangeContactsEmail(e){ this.setState({contacts: {...this.state.contacts,email:e.target.value} }) };
  handleChangeContactsWhatsapp(e){ this.setState({contacts: {...this.state.contacts,telefono:e.target.value} }) };

  render(){
    if(this.state.error){
      return <MsgError message={this.state.error.message}/>
    }else if(!this.state.isLoaded){
      return <Loading/>
    }
    return(
      <React.Fragment>
        <main className="newtask">
          <div className="newtask-aside">
            <Aside/>
          </div>
          <div className="newtask-body">
            <div className="card">
              <div className="card-header">
                <h2>Editar abonado número {this.props.match.params.abonado}</h2>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="name">Nombre</label>
                      <input className="form-control" id="name" placeholder="Nombre del cliente" type="text" onChange={this.handleChangeName.bind(this)} value={this.state.razon} required/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="abonado">Número de abonado</label>
                      <input className="form-control" disabled id="abonado" placeholder="Nro. abonado" type="text" onChange={this.handleChangeDNI.bind(this)} value={this.state.abonado} required/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label>Localidad</label>
                      <select className="form-control" value={this.state.location.localidad} onChange={this.handleChangeLocationCity.bind(this)}>
                        {this.state.localidades.map((c,i) => {
                          return (<option value={c} key={i}>{c}</option>)
                        })}
                      </select>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="address">Calle</label>
                      <input className="form-control" id="address" type="text" onChange={this.handleChangeLocationAddress.bind(this)} value={this.state.location.calle}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="numero">Número</label>
                      <input className="form-control" id="numero" type="text" onChange={this.handleChangeLocationNumero.bind(this)} value={this.state.location.numero}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="barrio">Barrio</label>
                      <input className="form-control" id="barrio" type="text" onChange={this.handleChangeLocationBarrio.bind(this)} value={this.state.location.barrio}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="pisodep">Depto.</label>
                      <input className="form-control" id="pisodep" type="text" onChange={this.handleChangeLocationPisodep.bind(this)} value={this.state.location.pisodep}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="link">Enlace de ubicación</label>
                      <input className="form-control" id="link" type="text" onChange={this.handleChangeLocationLink.bind(this)} value={this.state.location.link}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="email">Email</label>
                      <input className="form-control" id="email" type="text" onChange={this.handleChangeContactsEmail.bind(this)} value={this.state.contacts.email}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="whatsapp">Número de teléfono</label>
                      <input className="form-control" id="whatsapp" type="text" onChange={this.handleChangeContactsWhatsapp.bind(this)} value={this.state.contacts.telefono}/>
                    </fieldset>
                  </div>
                 
                  {this.state.enabled ? (<button type="submit" className="btn btn-primary">Editar</button>):( <button disabled type="submit" className="btn btn-primary">Editar</button>)}
                  { this.state.errorForm ? (
                      <div className="alert alert-danger mt-4" role="alert">
                        {this.state.errorForm}
                      </div>) : 
                      (null)
                  }
                  { this.state.success ? (
                      <div className="alert alert-success mt-4" role="alert">
                        {this.state.success}
                      </div>
                    ):
                    (null)
                  }
                </form>

                <div className="form-row mb-4 mt-4">
                  <button className="btn btn-dark m-1" onClick={()=>this.uploadImageFront()}>Subir imagen de la casa</button>  
                  {this.state.image ? (<button className="btn btn-secondary m-1" onClick={()=>this.seeFrontImage()}>Ver imagen de la casa</button>):null}
                  {(this.state.location.link!=="") ? (<button className="btn btn-secondary m-1" onClick={()=>this.seeLocationLink()}>Ver ubicación</button>):null}
                </div>

              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default ClientEdit;
