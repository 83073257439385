import React from 'react';

export default function Kit(props){
    if((props.completed)||(props.canceled_at)||(props.kit)||(props.end_without_to_complet_at)){
        return( 
            <div className="card-footer">
				<h6 className="mb-2 text-muted">Datos de equipo</h6>
				    <div className="row">
					    <div className="col">
							<label style={{"width":"100%"}}>Tipo de equipo</label>
							<input disabled value={props.kitSelected}></input>
						</div>
						<div className="col">
							<label style={{"width":"100%"}}>Potencia</label>
							<input value={props.kitPower} disabled/>
						</div>
					</div>
			</div>
        );
    }
    return (
        <div className="card-footer">
			<h6 className="mb-2 text-muted">Datos de equipo</h6>
			<div className="row">
				<div className="col">
					<label>Tipo de equipo</label>
					<select className="form-control" value={props.kitSelected} onChange={(e)=>props.handleChangeKitSelected(e.target.value)}>
					{props.kits.map((k) => {
						return <option value={k.name} key={k._id}>{k.name}</option>
					})}
					</select>
				</div>
				<div className="col">
					<label style={{"width":"100%"}}>Potencia</label>
					<input value={props.kitPower} onChange={(e) => props.handleChangeKitPower(e.target.value)}/>
				</div>				
			</div>
			<div className="row">
				<div className="col">
					<button className="btn btn-info mt-2" onClick={()=>props.savePower()}>Guardar potencia</button>
				</div>
			</div>
		</div>	
    );
}