import React,{Component} from 'react';
import { BrowserRouter,Route, Switch } from 'react-router-dom';
//context with initial_state
import AppContext,{initial_state} from './context/app-context';
//sign
import SignIn from './pages/sign/SignIn';
import SignUp from './pages/sign/SignUp';
import Accounts from './pages/sign/Accounts';
//vehicles
import Vehicles from './pages/vehicles/Vehicles';
import TimesVehicle from './pages/vehicles/TimesVehicle';
//tasks
import Tasks from './pages/tasks/Tasks';
import TaskEdit from './pages/tasks/TaskEdit';
import Task from './pages/tasks/Task';
import NewTask from './pages/tasks/NewTask';
//components
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
//clients
import Clients from './pages/clients/Clients';
import NewClient from './pages/clients/NewClient';
import ClientEdit from './pages/clients/ClientEdit';
//uploads
import FrontUpload from './pages/uploads/FrontUpload';
import BackUpload from './pages/uploads/BackUpload';
import UploadAudio from './pages/uploads/UploadAudio';
//message
import Message from './pages/message/Message';
//monitor
import Monitor from './pages/monitor/Monitor';
//roadmap
import RoadMap from './pages/roadmap/RoadMap';
//locations
import Locations from './pages/locations/Locations';
//service
import Service from './pages/service/Service';
//search
import Search from './pages/search/Search';
//statistic
import Statistic from './pages/statistic/Statistic';
//technicians
import Technicians from './pages/technicians/Technicians';
//kit
import Kit from './pages/kit/Kit';
//delayed
import Delayed from './pages/delayed/Delayed';
//match
import NoMatch from './pages/match/NoMatch';
//zones
import Zones from './pages/zones/Zones';
//changepass
import ChangePass from './pages/sign/ChangePass';

class Root extends Component{

	state = {
		...initial_state,
		setAuth : (auth) => this.setState({auth}),
		setSubscriber: (subscriber) => this.setState({subscriber}),
		setSubscriberName: (subscriberName) => this.setState({subscriberName}),
		setFechaSubida: (fechaSubida) => this.setState({fechaSubida}),
		setRole: (role) => this.setState({role}),
		setFecha: (fecha) => this.setState({fecha})
	}

	render(){
		return(
			<AppContext.Provider value={this.state}>
				<BrowserRouter>
					<ScrollToTop>
						{this.state.auth ? (
							<React.Fragment>
								<Header/>
								<Switch>
									<Route exact path="/" render={()=>(
										this.state.auth ? (<RoadMap/>):(<SignIn/>)
									)}/>
									<Route path="/search" component={Search}/>
									<Route path="/roadmap/:id" component={Task}/>
									<Route path="/monitor" component={Monitor}/>
									<Route exact path="/vehicles" component={Vehicles}/>
									<Route exact path="/newtask" component={NewTask}/>
									<Route path="/signup" component={SignUp}/>
									<Route path="/technicians" component={Technicians}/>
									<Route path="/kit" component={Kit}/>
									<Route path="/accounts" component={Accounts}/>
									<Route path="/service/:id" component={Service}/>
									<Route path="/edittask/:id" component={TaskEdit}/>
									<Route path="/locations" component={Locations}/>
									<Route path="/times" component={TimesVehicle}/>
									<Route path="/tasks" component={Tasks}/>
									<Route path="/clients" component={Clients}/>
									<Route path="/statistic" component={Statistic}/>
									<Route path="/newclient" component={NewClient}/>
									<Route path="/uploadfront/:id" component={FrontUpload}/>
									<Route path="/uploadback/:id" component={BackUpload}/>
									<Route path="/upaudio" component={UploadAudio}/>
									<Route path="/clientedit/:abonado" component={ClientEdit}/>
									<Route path="/delayed" component={Delayed}/>
									<Route path="/message" component={Message}/>
									<Route path="/zones" component={Zones}/>
									<Route path="/changepass/:id" component={ChangePass}/>
									<Route component={NoMatch} />
								</Switch>
							</React.Fragment>
						):(
							<React.Fragment>
								<Switch>
									<Route exact path="/" render={()=>(
										this.state.auth ? (<RoadMap/>):(<SignIn/>)
									)}/>
									<Route component={NoMatch} />
								</Switch>
							</React.Fragment>
						)}
					</ScrollToTop>
					<Footer/>
				</BrowserRouter>
			</AppContext.Provider>
		);
	}

}

export default Root;
