import React from 'react';

function Footer() {
	const user = localStorage.getItem('email');
	if(user!==null){
		return(
			<footer className="pie">
				<h6 className="pie-body">Prolux Comser S.A.</h6>
			</footer>
		);
	}else {
		return null;
	}
}

export default Footer;
