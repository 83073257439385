import React from 'react';

function MsgError(props){
  return(
    <section className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">Lo sentimos!</h4>
        <p>Si tu estás leyendo este mensaje es porque algo malo paso.</p>
        <hr/>
        <p className="mb-0">{props.message}</p>
      </div>
    </section>
  );
}

export default MsgError;
