import React,{ Component } from 'react';

import { endpoint,endpointSocketMonitor,endpointSocketData	} from '../../config';
import moment from 'moment';
import TracertContext from '../../context/app-context';
import Loading from '../../components/Loading';

import Info from './Info';
import Circle from './Circle';

import socketIOClient from "socket.io-client";

class Monitor extends Component {
    static contextType = TracertContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: null,
            data: null,
            socketMonitor: null,
            socketData: null
        }
    }

    getTasks(){
      let fecha = moment().format("YYYY-MM-DD");
      let token = localStorage.getItem('token');
      //this.setState({isLoaded:false})
      fetch(`${endpoint}/tasks/fechas/${fecha}`,{headers:{
        'Content-Type': 'application/json','authorization': `${token}`
      }})
      .then(res => res.json())
      .then(response => {
        this.setState({data:response,isLoaded:true,loading:false})
      })
      .catch(err => console.log(err))
    }

    componentDidMount(){
        this.setState({
            socketMonitor:socketIOClient(endpointSocketMonitor),
            socketData: socketIOClient(endpointSocketData)
        });
    }

    componentDidUpdate(prevProps,prevState) {
        // Uso tipico (no olvides de comparar las props):
        if(this.state.socketData!==prevState.socketData){
            this.state.socketData.emit("initial_data");
            this.state.socketData.on("get_data",(data)=>{
                this.setState({data,isLoaded:true})
            })
        }
        if (this.state.socketMonitor !== prevState.socketMonitor) {
            this.state.socketMonitor.emit("subscriberUpdate");
            this.state.socketMonitor.on("subscriber",(data)=>{
                this.setState({data})
            });
        }
    }

    componentWillUnmount(){
        this.state.socketMonitor.close();
        this.state.socketData.close();
    }

    render(){
        const {isLoaded} = this.state;
        if(!isLoaded){
            return <Loading/>
        }
        return(
        <main className="monitor">
            <div className="monitor-header bg-success text-white">
                <h2>Monitor : {moment().format("DD/MM/YYYY")}</h2>
            </div>
            <div className="monitor-body">
            {this.state.data.map((k) => {
                return (
                <div key={k._id} className="monitor-body-item">
                    <h4>{k._id}</h4>
                <div>
                {k.tasks.map((t) =>{
                return (
                <React.Fragment>
                {   t.scheduled ? 
                (
                <div key={t._id} className={`monitor-body-item-item`}>
                    <div className="monitor-body-item-item-header">
                        <h5>{t.type}</h5>
                        <Circle status={t.status}/>
                    </div>
                    <div className="mt-2 text-light mb-2">
                        <div className="bg-white text-dark">{t.contacts.nombre}</div>
                    </div>
                    <p className="mb-1 text-dark">{t.location.city} / {t.location.address}</p>
                    <small className="text-dark">{t.time.begin} - {t.time.end} HS.</small>
                    <ul className="list-group mt-2 text-light">
                    {t.technicians.map((t,i) => {
                    return <li key={i} className="list-group-item bg-dark text-white">{t}</li>
                    })}
                    </ul>
                </div>
                ) :(
                <div key={t._id} className={`monitor-body-item-item`} style={{"backgroundColor":"#292b2c"}}>
                    <div className="monitor-body-item-item-header text-light">
                        <h5>{t.type}</h5>
                        <Circle status={t.status}/>
                    </div>
                    <div className="mt-2 text-light mb-2">
                        <div className="text-light">{t.contacts.nombre}</div>
                    </div>
                    <p className="mb-1 text-light">{t.location.city} / {t.location.address}</p>
                    <small className="text-light">SIN HORARIO ASIGNADO</small>
                    <ul className="list-group mt-2 text-light">
                    {t.technicians.map((t,i) => {
                    return <li key={i} className="list-group-item bg-white text-dark">{t}</li>
                    })}
                    </ul>
                </div>
                ) 
                }
            </React.Fragment>                   
            )
            })}
            </div>                   
            </div>
            )
            })}
            </div>
            <Info/>
        </main>
        );
    }
}

export default Monitor;

//<small className={`${t.status}`}></small>
//<small className={`${t.status}`}></small>