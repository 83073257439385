import React from 'react';
import { Link } from 'react-router-dom';
import Observations from './Observations';
import barrio from '../tasks/task/barrio';
import pisodepto from '../tasks/task/pisodepto';

export default function List(props) {
    if(props.visible) {
      return (
        <ul className="list-group mt-4">
          {props.tasks.map( t => {
            let zone = ``;
            for(let i=0;i<props.locations.length;i++){
              if(props.locations[i].name===t.location.city){
                zone = props.locations[i].zone;
              }
            }
            let bgc = '';
            for(let j=0;j<props.zones.length;j++){
                if(props.zones[j].name===zone){
                    bgc = props.zones[j].color;
                }
            }
            return (
              <Link key={t._id} className="list-group-item list-group-item-action" to={`/roadmap/${t._id}`} style={{"backgroundColor":`${bgc}`}}>
                <div className="roadmap-list-item">
                    <div className="roadmap-list-item-header">
                      <h5>{t.type}</h5>
                      <small className={`${t.status} p-2`}>{t.status.toUpperCase().replaceAll("_"," ")}</small>
                    </div>
                    <div className="roadmap-list-item-body">
                      <div className="roadmap-list-item-body-left">
                        <ul className="list-group list-group-horizontal mt-2 mb-2">
                          <li className="list-group-item bg-white text-dark">{t.contacts.nombre}</li>
                        </ul>
                        <p>{t.location.city} / {t.location.address} {t.location.number} {barrio(t.location.barrio)} {pisodepto(t.location.piso)}</p>
                        {((t.time.begin!=="25:00")&&(t.time.end!=="26:00")) ? ( <small>{t.time.begin} - {t.time.end} hs.</small>): ( <small>SIN HORA ASIGNADA</small>) }
                        <ul className="list-group mt-2 text-light">{t.technicians.map((t,i) => {
                          return <li key={i} className="list-group-item bg-dark text-white">{t}</li>
                        })}
                        </ul>
                      </div>
                      <div className="roadmap-list-item-body-right">
                        <Observations title={"OBSERVACIONES AL CREARSE"} observations={t.observations}/>
                        <Observations title={"OBSERVACIONES"} observations={t.service_observations}/>
                        {t.canceled_at ? (
                        <Observations title={"INFORME"} observations={t.canceled_at.toUpperCase()}/>
                        ):(null)}
                        {t.end_without_to_complet_at ? (
                          <Observations title={"INFORME"} observations={t.end_without_to_complet_info}/>
                        ):(null)}
                      </div>
                    </div>
                </div>
              </Link>
            );
          } )}
        </ul>
      )
    }else {
      return null;
    }
}