import React from 'react';

export default function Circle(props){
    let color = "";
    switch (props.status) {
        case "completada":
            color = "#5cb85c";
            break;
        case "completada_sin_terminar":
            color = "#5bc0de";
            break;
        case "retrasada":
            color = "#d9534f";
            break;
        case "pendiente":
            color = "#f0ad4e";
            break;
        case "cancelada":
            color = "#292b2c";
            break;
        default:
            color = "#2E2E2E";
            break;
    }
    return(
        <svg height="36" width="36">
            <circle cx="16" cy="16" r="16" stroke="white" stroke-width="1"  fill={color}/>
        </svg> 
    );
}