import React,{Component,createRef} from 'react';
import {endpoint3,endpointSocketSound} from '../config';
import TracertContext from '../context/app-context';
import logoAltavoz from '../images/altavoz.svg';
import logoMute from '../images/mudo.svg';

import socketIOClient from "socket.io-client";

export default class Alarm extends Component {

    static contextType = TracertContext;

    constructor(props){
        super(props);
        this.state = {
            src: `${endpoint3}/01.mp3`,
            type: 'audio/mp3',
            autoplay: false,
            muted: true,
            socket: null
        };
        this.alarma = createRef();
    }

    play(){
        this.alarma.current.play();
    }

    pause(){
        this.alarma.current.pause();
    }

    reLoad(){
        if(this.alarma.current){
            this.alarma.current.load();
            this.alarma.current.play();
        }   
    }

    unMuted(){
        if(this.alarma.current) this.alarma.current.muted=false;
        //this.setState({muted:false});
        this.setState({socket: socketIOClient(endpointSocketSound),muted:false});
    }

    muted(){
        if(this.alarma.current) this.alarma.current.muted=true;
        this.state.socket.close();
        this.setState({muted:true,socket:null});
    }

    componentDidMount(){
        //this.state.socket.emit("sound",localStorage.getItem('role'));
		//this.state.socket.on("get_sound",(data)=>{ this.reLoad(); })
    }

    componentDidUpdate(prevProps,prevState) {
		if(prevState.muted!==this.state.muted){
			if(!this.state.muted){
                this.state.socket.emit("sound",localStorage.getItem('role'));
		        this.state.socket.on("get_sound",(data)=>{ this.reLoad(); })
            }
		}
	}

    render(){
        return(
            <div id="alarm">
                {this.state.muted ? (
                    <button className="btn btn-outline-light" onClick={()=>this.unMuted()}><img src={logoMute} style={{"width":"32px","height":"32px"}} alt={"sin sonido"}/></button>
                ):(
                    <button className="btn btn-outline-light" onClick={()=>this.muted()}><img src={logoAltavoz} style={{"width":"32px","height":"32px"}} alt={"con sonido"}/></button>
                )
                }
                <audio ref={this.alarma} src={this.state.src} muted={true} type={this.state.type} autoPlay={this.state.autoplay}/>
            </div>
        );
    }
}

/*
                <button onClick={()=>this.play()}>PLAY</button>
                <button onClick={()=>this.pause()}>PAUSE</button>
                <button onClick={()=>this.reLoad()}>RELOAD</button>
el sonido tiene que saltar al estar la proxima tarea por retrasar iniciarse

hay una tarea programada de 10 a 11
se retrasa esta tarea (son las 11 y la tarea no se ha completado ni esta cancelada)

si la siguiente tarea no se inicializa antes de las 11
    sonar chicharra

la siguiente tarea no esta inicializada aun
hoy en dia la tarea 


si la tarea no esta inicializada para antes de esa hora sonar chicharra
ponerlas en un arreglo de sonadas

8 - 9
9 - 10


9 - 10

8 - 10

la tarea se tiene que inicializar si o si antes de la hora

*/