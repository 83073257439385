import React,{ Component } from 'react';
import { endpoint } from '../../config';
import {fromJS,Map} from 'immutable';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';

import initial_state from './initial_state';

class Kit extends Component {
  
  state = { ...initial_state }

  getKits(){
    fetch(`${endpoint}/kit`)
    .then(res => res.json())
    .then( (result) => this.setState({kits: fromJS(result),isLoaded:true}))
    .catch(err => console.log(err))
  }

  componentDidMount(){ this.getKits() }

  setKit(e){ this.setState({ kit: e.target.value }) }

  handleSubmit(e){
    if(this.state.kit!==''){
      let url = `${endpoint}/kit`;
  		var data = { name: this.state.kit.trim().toUpperCase(), created_by: localStorage.getItem('email') };
      let token = localStorage.getItem('token');
      this.setState({buttonEnable:false})
  		fetch(url, {
  		  method: 'POST',
  		  body: JSON.stringify(data),
  		  headers:{ 'Content-Type': 'application/json','authorization':`${token}`  }
  		}).then(res => res.json())
  		.then(result => this.setState({buttonEnable:true,kits:this.state.kits.push(Map({name:result.name,_id:result.id})),kit:'',error:null}))
   		.catch(error => console.error('Error:', error));
    }
    else { this.setState({error:'Debes ingresar un equipo'}) }
  }

  deleteKit({i,id},e){
    let token = localStorage.getItem('token');
    this.setState({buttonEnable:false});
    fetch(`${endpoint}/kit`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json','authorization':`${token}`},
      body: JSON.stringify({id})
    })
    .then(res => res.json())
    .then(res => this.setState({buttonEnable:true,kits:this.state.kits.delete(i)}))
  }

  render(){
    const {isLoaded,buttonEnable} = this.state;
    if (!isLoaded){ return(<Loading/>);
    }else {
      return(
      <main className="newtask">
        <div className="newtask-aside">
          <Aside/>
        </div>
        <div className="newtask-body">
          <div className="card">
          <div className="card-header">
            <h2>Lista de equipos</h2>
          </div>
          <div className="card-body">
          {(this.state.kits.size>0) ? (
            <ul className="list-group">
            {this.state.kits.map((v,i) => {
            return (
            <li className="d-flex justify-content-between align-items-center list-group-item" key={i}>
              <p>{v.get('name')}</p>
              {buttonEnable ? (
              <button className="btn btn-danger" onClick={(e)=>this.deleteKit({i,id:v.get('_id')},e)}>Eliminar</button>
              ):(
              <button className="btn btn-danger" disabled>Eliminar</button>
              )}
            </li>
            )
            })}
          </ul>
          ):(
          <ul className="list-group">
            <li className="d-flex justify-content-between align-items-center list-group-item">no hay equipos</li>
          </ul>
          )}
        </div>
      </div>
              <div className="card mt-4">
                <div className="card-header">
                  <h2>Agregar equipo</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="kit">Equipo</label>
                    <input id="kit" className="form-control" type="text" value={this.state.kit} onChange={this.setKit.bind(this)}/>
                  </div>
                  {this.state.error ? (<div className="alert alert-danger">{this.state.error}</div>) : null }
                </div>
                <div className="card-footer">
                  {buttonEnable ? (
                      <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Añadir equipo</button>
                  ):(
                      <button className="btn btn-primary" disabled>Añadir equipo</button>
                  )}
                </div>
              </div>

            </div>
          </main>
      );
    }

  }
}

export default Kit;
