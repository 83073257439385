import React, { useState, useContext } from 'react';
import { endpoint } from '../../config';
import logo from '../../images/tracert.svg';
import MsgError from '../../components/MsgError';

import AppContext from '../../context/app-context';

function SignIn () {

	const context = useContext(AppContext);

	const [email,setEmail] = useState('');
	const [password,setPassword] = useState('');
	const [error,setError] = useState(null);
	const [loginErrors,setLoginErrors] = useState(false);
	const [buttonEnable,setEnable] = useState(true);

	function ingresar(){
		context.setAuth(true);
	} 

	function submit(e){
		e.preventDefault();
		var url = `${endpoint}/users/signin`;
		var data = {email:email.toUpperCase().trim(), password:password.trim() };
		setEnable(false);
		fetch(url, {
					method: 'POST',
				  body: JSON.stringify(data),
				  headers:{	'Content-Type': 'application/json'	}
				}).then(res => res.json())
				.then(response => {
					setEnable(true);
					if (response.token){
						if( (response.user.role==='admin')||(response.user.role==='root') ){
							localStorage.setItem('email',response.user.email);
							localStorage.setItem('role',response.user.role);
							localStorage.setItem('token',response.token);
							//window.location = '/';
							ingresar();
						}else{
							setLoginErrors('Esta vista es exclusiva de los administradores');
						}
					}else {
						setLoginErrors(response.error)
					}
				}).catch( error => {
						setError(error);
				});
	}

	if (error) {
		return (
			<MsgError error={error}/>
		);
	} else {
		return(
			<section className="signin d-flex flex-column justify-content-center align-items-center min-vh-100">

				<form onSubmit={submit} className="signin-form">

					<div className="signin-form-header form-group d-flex flex-column justify-content-center align-items-center">
						<img alt="logo" src={logo} style={{"width":"100px"}} className="pb-2"/>
						<h6>Tracert</h6>
					</div>

					<div className="signin-form-body">

						<fieldset className="form-group">
							<label htmlFor="email">Nombre de usuario</label>
							<input className="form-control" id="email" type="text" onChange={	e	=>	setEmail(e.target.value)	}
								value={email} placeholder="Introduce tu nombre de usuario" required
							/>
						</fieldset>

						<fieldset className="form-group">
							<label htmlFor="password">Contraseña</label>
							<input className="form-control" id="password" type="password" onChange={	e => setPassword(e.target.value)	}
							 	value={password} placeholder="Introduce tu contraseña" required
							/>
						</fieldset>

						{(!loginErrors) ? null : (<div className="alert alert-danger">{loginErrors}</div>)}
						<div>
							{buttonEnable ? (
								<button className="btn btn-primary w-100">Iniciar sesión</button>
							):(
								<button className="btn btn-primary w-100" disabled>Iniciar sesión</button>
							)}
						</div>
					</div>
				</form>

			</section>
		);
	}
}

export default SignIn;
