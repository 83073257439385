import React,{ Component } from 'react';
import {  endpoint, endpointImage  } from '../../config';
import Aside from '../../components/Aside';

import TracertContext from '../../context/app-context';

class FrontUpload extends Component {

  static contextType = TracertContext;

  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        error: null,
        uploading: false
      }
   
  }

  actualizarImagenesEnTareas(){
    
    const user = localStorage.getItem('email');
    const token = localStorage.getItem('token');
      
      fetch(`${endpoint}/tasks/frontimage`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json','authorization':`${token}`},
        body: JSON.stringify({
          user,
          name:this.context.subscriberName,
          image:`${this.context.subscriber}.jpg`
        })
      })
      .then(res => res.json())
      .then(res => {
        if(res.success){
          this.setState({uploading:false})
          this.props.history.push(`/clientedit/${this.context.subscriber}`);
        }else{
          this.setState({uploading:false,error:"Hubo un problema al subir la imagen"});
        }
      })
  }

  actualizarImagen(name){
    const user = localStorage.getItem('email');
    const token = localStorage.getItem('token');
      
      fetch(`${endpoint}/clients/frontimage`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json','authorization':`${token}`},
        body: JSON.stringify({
          id: this.props.match.params.id,
          user,name
        })
      })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if(res.success){
          //this.setState({uploading:false})
          this.actualizarImagenesEnTareas();
          //this.props.history.push(`/clientedit/${this.context.subscriber}`);
        }else{
          this.setState({uploading:false,error:"Hubo un problema al subir la imagen"});
        }
      })
  }

   onClickHandler = () => {  
      let data = new FormData()
      this.setState({uploading:true});
       data.append('name',`${this.context.subscriber}`);
       data.append('date','subscribers');
       data.append('file', this.state.selectedFile);
       fetch(`${endpointImage}/upload`, {
        method: 'POST',
        body: data
      })
      .then(response => response.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        if(response){
            this.actualizarImagen(`${this.context.subscriber}.jpg`);
          }else{
            this.setState({error:"Error subiendo la imagen"})
          }
      });
    
  }

  onChangeHandler=event=>{
     if(event.target.files[0].type!=="image/jpeg"){
      this.setState({
        error: "Debes seleccionar una imagen con formato jpeg."
      })
     }else if((event.target.files[0].size/1048576)>=21){
       this.setState({
        error: "El peso de la imagen debe ser inferior o igual a 20MiB"
       })
     }else{
      this.setState({
        error: null,
        selectedFile: event.target.files[0],
        loaded: 0,
      })
     }
  }

  renderButton(selectedFile,error,uploading){
    if(selectedFile===null){
      return <button type="button" className="btn btn-success btn-block" disabled>Subir imagen</button>;
    }else if(uploading===true){
      return <button type="button" className="btn btn-success btn-block" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Subiendo ...</button>;
    }else if(selectedFile!==null&&uploading===false){
      return <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler} >Subir imagen</button>;
    }
  }

  render(){
    const { error, uploading, selectedFile } = this.state;
    return(
      <main className="newtask">
        <div className="newtask-aside">
          <Aside/>
        </div>
        <div className="newtask-body">
          <div className="card">
            <div className="card-header">
              <h2>Subida de imagen de la casa</h2>
            </div>
            <div className="card-body">
              <h4>La imagen a subir puede pesar hasta 20MiB y su formato debe ser jpeg.</h4>
              <div className="form-group">
                <label htmlFor="exampleFormControlFile1">Seleccione una imagen</label>
                {(!uploading) ? (<input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.onChangeHandler}/>):(<input type="file" className="form-control-file" id="exampleFormControlFile1" disabled/>)}
              </div>
              {this.renderButton(selectedFile,error,uploading)}
              {error ? <div className="alert alert-danger mt-2">{error}</div> : null } 
            </div>
          </div>
        </div>
      </main>
    );

  }
}

export default FrontUpload;
