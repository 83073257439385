import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';

class Clients extends Component {

  state = {
      clients : [],
      numero: "",
      isLoaded : true,
      errorForm: null,
      enabled: true
  }

  fetchClient(numero){
    this.setState({isLoaded:false})
    if(isNaN(numero)){
      fetch(`${endpoint}/clients/names/${numero}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            clients: result
          });
        }
      ).catch(err => console.log(err))
    }else{
      fetch(`${endpoint}/clients/${numero}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            clients: result
          });
        }
      ).catch(err => console.log(err))
    }
  }

  onChangeNumero(numero){this.setState({numero})};

  eliminar(data){
    let r = window.confirm(`¿ Desea eliminar el abonado número ${data.abonado} ?`);
    if(r){
       this.setState({enabled:false})
       fetch(`${endpoint}/clients`, {
        method: 'DELETE',
        headers:{ 'Content-Type': 'application/json','authorization':localStorage.getItem('token')},
        body: JSON.stringify({id: data.id})
      })
      .then(res => res.json())
      .then(res => {
        this.setState({enabled:true});
        if (res.success===true){
          this.fetchClient();
        }else {
          console.log('ups');
        }
      })
    }
  }

  editar(data){
    this.props.history.push(`/clientedit/${data.abonado}`);
  }

  handleSubmit(e){
    e.preventDefault();
    this.fetchClient(this.state.numero);
  }

  addClient(){
    this.props.history.push('/newclient');
  }

  render(){
    const {isLoaded} = this.state;
    return(
      <React.Fragment>
        <main className="newtask">
          { isLoaded ? (
            <React.Fragment>
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h2>Cliente</h2>
                </div>
                <div className="card-body">
                  <form onSubmit={(e)=>this.handleSubmit(e)}>
                    <div className="form-row align-items-center">
                      <div className="col-auto my-1">
                        <input placeholder="Nombre o número del cliente" id="inlineFormMin" type="text" className="form-control" value={this.state.numero} onChange={(e)=>this.onChangeNumero(e.target.value)} required/>
                      </div>
                      <div className="col-auto my-1">
                        <button type="submit" className="btn btn-primary">Buscar</button>
                      </div>

                    </div>
                    {this.state.errorForm ? (<div class="alert alert-danger mt-3" role="alert">
                    {this.state.errorForm}
                  </div>):(null)}
                  </form>
                
                <div className="table-responsive">
                  <table className="table mt-3" style={{"width":"100%"}}>
                        <thead>
                          <tr>
                            <th scope="col">Abonado</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Localidad</th>
                            <th scope="col">Calle</th>
                            <th scope="col">Número</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                    {this.state.clients.map((c,i) => {
                      return (
                          <tr>
                            <th scope="row">{c.abonado}</th>
                            <td>{c.razon}</td>
                            <td>{c.localidad}</td>
                            <td>{c.calle}</td>
                            <td>{c.numero}</td>
                            <td>{c.telefono}</td>
                            <td>
                              <button type="button" className="btn btn-warning m-1" onClick={()=>this.editar({abonado:c.abonado})}>Editar</button>
                              {this.state.enabled ? ( <button onClick={()=>this.eliminar({id:c._id,i,abonado:c.abonado})} className="btn btn-danger">Eliminar</button>):( <button onClick={()=>this.eliminar({id:c._id,i,abonado:c.abonado})} className="btn btn-danger" disabled>Eliminar</button>)}
                            </td>
                          </tr>
                      )
                    })}
                        </tbody>
                        <tfoot>
                          <button type="submit" className="btn btn-primary" onClick={()=>this.addClient()}>Agregar Cliente</button>
                        </tfoot>
                  </table>
                </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <Aside/>
            </div>
            </React.Fragment>
          ) : (
            <Loading/>
          ) }
        </main>
      </React.Fragment>
    );
  }
}

export default Clients;
