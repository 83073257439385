export const getInfo = (response) => {
    let info = [];
    let d = {};
    for (let i=0;i<response.length;i++){
        d = response[i];
        d.isChecked = false;
        info.push(d);
    }
    return info;
}

export const getDataToUpdate = (newData,oldData) => {
    let info = [];
	let d = {};
	for (let i=0;i<newData.length;i++){
	    d = newData[i];
		oldData[i] ? d.isChecked=oldData[i].isChecked : d.isChecked=false;
		info.push(d);
    }
    return info;
}

export const getStatusCheckBox = (data,i) => {
    let info = [];
	let d = {};
	for (let j=0;j<data.length;j++){
		d = data[j];
		if 	(j===i) d.isChecked = !d.isChecked;
		info.push(d);
    }
    return info;
}

const barrio = (unBarrio) => (unBarrio&&unBarrio!=="") ? `B ${unBarrio}` : ''
const pisodepto = (unPisoDepto) => (unPisoDepto&&unPisoDepto!=="") ? `DEPT ${unPisoDepto}` : ''

export const getMoreData = (task) => {
    let moreData = [];
    moreData.push(task.contacts.nombre);
	moreData.push(task.location.city);
	let number = task.location.number;
	if(isNaN(number)) number = "";
	moreData.push(`${task.location.address} ${number} ${barrio(task.location.barrio)} ${pisodepto(task.location.piso)}`);
	moreData.push(task.contacts.whatsapp);
	moreData.push(task.type);
	moreData.push(`${task.time.begin}-${task.time.end}`);
	moreData.push(task.technicians.join(', '));
    moreData.push(task.observations);
    return moreData;
}