import React,{  Component } from 'react';
import {  endpoint  } from '../../config';
import Loading from '../../components/Loading';
import MsgError from '../../components/MsgError';
import Aside from '../../components/Aside';

class NewClient extends Component {

  state = {
    razon: '', abonado: '',
    location: { localidad: '', calle: '', numero: '', barrio: '' , pisodep: '', link: '' },
    contacts: { email: '', telefono: ''},
    localidades: [],
    isLoaded: false,
    error: null,
    errorForm: null,
    success: null,
    enabled: true
  }

  getLocations(){
    this.setState({isLoaded:false})
    fetch(`${endpoint}/locations`)
      .then(res => res.json())
      .then(
        (result) => {
           let localidades = [];
           for (let i=0;i<result.length;i++){
             localidades = localidades.concat(result[i].name);
           }
           this.setState({isLoaded: true,localidades,location:{...this.state.location,localidad:localidades[0]}});
        }
      ).catch(err => this.setState({error:err}))
  }

  componentDidMount(){
    this.getLocations();
  }

  handleSubmit(e){
    e.preventDefault();
    console.log(this.state);
    let {razon,abonado} = this.state;
    razon = razon.toUpperCase().trim();
    abonado = abonado.toUpperCase().trim();
    let location = {
      localidad : this.state.location.localidad.toUpperCase().trim(),
      calle: this.state.location.calle.toUpperCase().trim(),
      numero: this.state.location.numero.trim(),
      barrio: this.state.location.barrio.toUpperCase().trim(),
      pisodep: this.state.location.pisodep.toUpperCase().trim(),
      link: this.state.location.link.trim()
    };
    let contacts = {
      email: this.state.contacts.email.trim(),
      telefono: this.state.contacts.telefono.trim()
    }
    this.setState({errorForm:null,success:null,enabled:false});
    if(isNaN(abonado)){
      this.setState({errorForm:"El número de abonado tiene que ser un valor numérico",enabled:true});
    }else{
      let url = `${endpoint}/clients`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({razon,abonado,location,contacts}),
        headers:{ 'Content-Type': 'application/json','authorization':localStorage.getItem('token')  }
      }).then(res => res.json())
      .then(result => {
        if(result.error){
          this.setState({enabled:true,errorForm:result.error,razon:"",abonado:"",location:{localidad:"",calle:"",numero:"",barrio:"",pisodep:""},contacts:{email:"",telefono:""}});
        }else{
          this.setState({enabled:true,errorForm:null,success:"El abonado fue creado exitosamente",razon:"",abonado:"",location:{localidad:"",calle:"",numero:"",barrio:"",pisodep:""},contacts:{email:"",telefono:""}});
        }
      })
      .catch(error => {
        this.setState({ error : error });
      });
      
    }
    
  }

  handleChangeName(e){ this.setState({ razon: e.target.value }) };
  handleChangeDNI(e){ this.setState({ abonado: e.target.value }) };
  handleChangeLocationCity(e){ this.setState({location: {...this.state.location,localidad:e.target.value} }) };
  handleChangeLocationAddress(e){ this.setState({location: {...this.state.location,calle:e.target.value} }) };
  handleChangeLocationNumero(e){this.setState({location:{...this.state.location,numero:e.target.value}})};
  handleChangeLocationBarrio(e){this.setState({location:{...this.state.location,barrio:e.target.value}})}
  handleChangeLocationPisodep(e){this.setState({location:{...this.state.location,pisodep:e.target.value}})};
  handleChangeLocationLink(e){this.setState({location:{...this.state.location,link:e.target.value}})};
  handleChangeContactsEmail(e){ this.setState({contacts: {...this.state.contacts,email:e.target.value} }) };
  handleChangeContactsWhatsapp(e){ this.setState({contacts: {...this.state.contacts,telefono:e.target.value} }) };

  render(){
    if(this.state.error){
      return <MsgError message={this.state.error.message}/>
    }else if(!this.state.isLoaded){
      return <Loading/>
    }
    return(
      <React.Fragment>
        <main className="newtask">

          <div className="newtask-aside">
            <Aside/>
          </div>

          <div className="newtask-body">
            <div className="card">
              <div className="card-header">
                <h2>Crear una nuevo cliente</h2>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="name">Nombre</label>
                      <input className="form-control" id="name" placeholder="Nombre del cliente" type="text" onChange={this.handleChangeName.bind(this)} value={this.state.razon} required/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="abonado">Número de abonado</label>
                      <input className="form-control" id="abonado" placeholder="Nro. abonado" type="text" onChange={this.handleChangeDNI.bind(this)} value={this.state.abonado} required/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label>Localidad</label>
                      <select className="form-control" value={this.state.location.localidad} onChange={this.handleChangeLocationCity.bind(this)}>
                        {this.state.localidades.map((c,i) => {
                          return (<option value={c} key={i}>{c}</option>)
                        })}
                      </select>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="address">Calle</label>
                      <input className="form-control" id="address" type="text" onChange={this.handleChangeLocationAddress.bind(this)} value={this.state.location.calle}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="numero">Número</label>
                      <input className="form-control" id="numero" type="text" onChange={this.handleChangeLocationNumero.bind(this)} value={this.state.location.numero}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="barrio">Barrio</label>
                      <input className="form-control" id="barrio" type="text" onChange={this.handleChangeLocationBarrio.bind(this)} value={this.state.location.barrio}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="pisodep">Depto.</label>
                      <input className="form-control" id="pisodep" type="text" onChange={this.handleChangeLocationPisodep.bind(this)} value={this.state.location.pisodep}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="link">Enlace de ubicación</label>
                      <input className="form-control" id="link" type="text" onChange={this.handleChangeLocationLink.bind(this)} value={this.state.location.link}/>
                    </fieldset>
                  </div>
                  <div className="form-row mb-4">
                    <fieldset className="col">
                      <label htmlFor="email">Email</label>
                      <input className="form-control" id="email" type="text" onChange={this.handleChangeContactsEmail.bind(this)} value={this.state.contacts.email}/>
                    </fieldset>
                    <fieldset className="col">
                      <label htmlFor="whatsapp">Número de teléfono</label>
                      <input className="form-control" id="whatsapp" type="text" onChange={this.handleChangeContactsWhatsapp.bind(this)} value={this.state.contacts.telefono}/>
                    </fieldset>
                  </div>
                  
                  {this.state.enabled ? (<button type="submit" className="btn btn-primary">Crear</button>):(<button disabled type="submit" className="btn btn-primary">Crear</button>)}
                  { this.state.errorForm ? (
                      <div className="alert alert-danger mt-4" role="alert">
                        {this.state.errorForm}
                      </div>) : 
                      (null)
                  }
                  { this.state.success ? (
                      <div className="alert alert-success mt-4" role="alert">
                        {this.state.success}
                      </div>
                    ):
                    (null)

                  }
                </form>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default NewClient;
