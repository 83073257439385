import React from 'react';
import moment from 'moment';
import BtnWhatsapp from '../../../components/BtnWhatsapp';
import barrio from './barrio';
import pisodepto from './pisodepto';

export default function TaskReport(props){
    return(
        <div className="card-body">
			<div className="table-responsive">
			    <table className="table" id="tablon">
					<tbody>
						<tr>
							<th scope="row" className="bg-primary text-light">TIPO DE TAREA</th>
				    		<td>{props.type}</td>
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">CREADOR</th>
							<td>{props.creator} el {moment(props.created_at).format('DD-MM-YYYY HH:mm')}</td>
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">NOMBRE DEL CLIENTE</th>
							<td>{props.contacts.nombre}</td>
						</tr>
						<tr>
						    <th scope="row" className="bg-primary text-light">LOCALIDAD / DIRECCIÓN</th>
							<td>{props.location.city} {props.location.address} {props.location.number} {pisodepto(props.location.piso)} {barrio(props.location.barrio)} {props.location.link ? (<button className="btn btn-warning m-1" onClick={()=>window.open(`${props.location.link}`)}>Ver ubicación</button>):(null)}</td>
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">HORARIO</th>
							{((props.time.begin!=="25:00")&&(props.time.end!=="26:00")) ? (<td>DESDE {props.time.begin} HASTA {props.time.end} HS</td>):(<td>SIN HORA ASIGNADA</td>)}													
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">TÉCNICOS A CARGO</th>
						    <td>
								<ul className="mb-2 list-group list-group-horizontal">
									{props.technicians.map((t,i) => {
									return (<li className="list-group-item" key={i}>{t}</li>)
									})}
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">OBSERVACIONES</th>
							<td>{	(props.observations==='') ? "SIN OBSERVACIONES"	:	`${props.observations.toUpperCase()}` }</td>
						</tr>
						<tr>
							<th scope="row" className="bg-primary text-light">ESTADO</th>
							<td>
								{((props.completed)||(props.started_at)||(props.end_without_to_complet_at)) ? (null):("SIN COMPLETAR") }
								{props.started_at ? (
								<React.Fragment> INICIADA POR {props.started_by} el {moment(props.started_at).format('YYYY-MM-DD HH:mm')}
								</React.Fragment>):(null)}
								{props.end_without_to_complet_at ? (<React.Fragment>COMPLETADA SIN TERMINAR POR {props.end_without_to_complet_by} EL {moment(props.end_without_to_complet_at).format("YYYY-MM-DD HH:mm")}</React.Fragment>):(null)}
								{props.completed ? (<React.Fragment> COMPLETADA POR {props.completed_by} EL {moment(props.completed_at).format('YYYY-MM-DD HH:mm')}</React.Fragment>):(null)}
								{props.canceled_at ? (<React.Fragment>, CANCELADA POR {props.canceled_by} EL {moment(props.canceled_at).format('YYYY-MM-DD HH:mm')}</React.Fragment>) : (null) }
								</td> 
							</tr>
							<tr>
								<th scope="row" className="bg-primary text-light">EMAIL</th>
								<td>{props.contacts.email}</td>
							</tr>
							<tr>
								<th scope="row" className="bg-primary text-light">NÚMERO DE TELEFONO</th>
								<td>
								{ (props.contacts.whatsapp!=='') ? (
								<React.Fragment>
								{props.contacts.whatsapp}
								<BtnWhatsapp number={props.contacts.whatsapp} msg={"Estimado cliente, desde NETFLY nos comunicamos con usted para informarle que en el día de hoy los técnicos irán a su domicilio."}/>
								</React.Fragment>
								):(
								null
							)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
    );
}
