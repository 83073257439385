import React,{	Component	} from 'react';
import { fromJS	} from 'immutable';
import moment from 'moment';
import {	endpoint, toHome, endpointSocketData, endpointSocketScheduled	} from '../../config';

import Hour from '../../components/Hour';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';
import MsgError from '../../components/MsgError';

import { DayPickerSingleDateController } from 'react-dates';

import TracertContext from '../../context/app-context';

import socketIOClient from "socket.io-client";

class NewTask extends Component {

	static contextType = TracertContext;

	state = {
		vehicles: [], vehicle: '',
		duration: "01:00",
		type: "",
		abonado: "", errorAbonado: null,
		tasks:[],
		tasks2:[],
		date: this.context.fecha,
		end: "",
		time: { begin: "08:00", end: "09:00" },
		technician: '', technicians: [],
		selects: fromJS([]),
		observations: '',
		locations: [], location: { city: "SELECCIONA UNA", address: "",number:"", piso: "", barrio: "", link: ""},
		contacts: { email: "", whatsapp: "", nombre: "" },
		errorForm: null,
	  	error: null,
		isLoaded: false,
		buttonEnable: true,
		updated: false,
		images: { front: null, back: null },
		scheduled: "true",
		socketScheduled: null,
		socketData: null
	}

	handleChangeScheduled(scheduled){this.setState({scheduled})}

	handleChangeDuration(duration){ this.setState({ duration }) }
	handleChangeAbonado(abonado){this.setState({abonado})};
	handleChangeBarrio(barrio){this.setState({location:{...this.state.location,barrio}})};
	handleChangeNumero(number){this.setState({location:{...this.state.location,number}})};
	handleChangePiso(piso){this.setState({location:{...this.state.location,piso}})};
	handleChangeNombre(nombre){this.setState({contacts:{...this.state.contacts,nombre}})};
	handleChangeObservations(observations){ this.setState({	observations	}) }
	handleChangeVehicle(vehicle){ this.setState({	vehicle	}) }
	handleChangeType(type){ this.setState({	type	})}
	handleChangeLocationCity(city){	this.setState({	location:{	...this.state.location,	city	}	})}
	handleChangeLocationAddress(address){ this.setState({	location:{	...this.state.location,	address	}})}
	handleChangeContactsEmail(email){	this.setState({	contacts:	{	...this.state.contacts,	email	}})}
	handleChangeContactsWhatsapp(whatsapp){	this.setState({	contacts:	{	...this.state.contacts,	whatsapp	}});}
	handleChangeDate(date){	this.setState({	date	})}
	handleChangeTimeBegin(begin){	this.setState({	time: {	...this.state.time,	begin	}})}
	handleChangeTimeEnd(end){	this.setState({	time: {	...this.state.time,	end	}})}
	handleChangeTechnician(technician){	this.setState({	technician	})}
	selectTechnician(technician,e){	this.setState(state => ({	technicians: state.technicians.push(technician)	}))}
	deleteTechnician(i,e){	this.setState(state => ({	technicians: state.technicians.delete(i)	}))}
	handleSelect({technician}){ this.setState({selects:this.state.selects.push(technician)}) }
	handleDelete(i,e){ this.setState({selects:this.state.selects.delete(i)}) }

	getVehicles(){
    fetch(`${endpoint}/vehicles`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({vehicles: result, vehicle: result[0].name});
        }
      ).then(()=>this.getTimes()).then(()=>this.getTechnicians()).then(()=>this.getLocations()).then(()=>this.getTaskTypes())
			.catch(err => console.log(err))
	}

	getTechnicians(){
    fetch(`${endpoint}/technicians`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({technician:result[0].name,technicians: result});
      })
			.catch(err => console.log(err))
  }

	getTaskTypes(){
    fetch(`${endpoint}/tasktypes`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({tasks: result,type:result[0].name,isLoaded:true});
      })
			.catch(err => console.log(err))
  }

	getLocations(){
    fetch(`${endpoint}/locations`)
      .then(res => res.json())
      .then(
        (result) => {
					 let localidades = [];
					 localidades = localidades.concat('SELECCIONA UNA');
					 let i;
					 for (i=0;i<result.length;i++){
						 localidades = localidades.concat(result[i].name);
					 }
           this.setState({locations: localidades,location:{...this.state.location,city:localidades[0]}});
        }
      ).catch(err => this.setState({error:err}))
  }

	buscarAbonado(){
		if(this.state.abonado!==""&&!isNaN(this.state.abonado)){
			this.setState({
				errorAbonado:null,
				location:{...this.state.location,piso:"",barrio:"",address:"",number:""},
				contacts:{...this.state.contacts,nombre:"",whatsapp:"",email:""}
			})
			fetch(`${endpoint}/clients/${this.state.abonado}`)
      		.then(res => res.json())
      		.then(	result =>	{
					result = result[0];
					if(!result){
						this.setState({
							errorAbonado:'El abonado no existe, si desea obtenerlo debe de agregarlo',
							location:{...this.state.location,piso:"",barrio:"",address:"",number:"", link: ""},
							contacts:{...this.state.contacts,nombre:"",email:"",whatsapp:""}
						})
					}else{
						let nombre,whatsapp,email,city,barrio,piso,address,number,image,link;
						nombre = whatsapp = email = city = barrio = piso = address = number = image = link = "";
						if(result.razon){	nombre=result.razon;	}
						if(result.whatsapp){	whatsapp=result.telefono;	}
						if(result.email){	email=result.email;	}
						if(result.localidad){	city=result.localidad;	}
						if(result.barrio){	barrio=result.barrio;	}
						if(result.pisodep){	piso=result.pisodep;	}
						if(result.calle){	address=result.calle;	}
						if(result.numero){	number=result.numero.toString();	}
						if(result.image){ image=result.image; }
						if(result.link){ link = result.link; }
						this.setState({
							errorAbonado: null,
							images : { ...this.state.images,front: image},
							contacts:{ ...this.state.contacts,nombre,whatsapp,email},
							location:{ ...this.state.location,city,barrio,piso,address,number,link}
						})
					}
				})
			.catch(err => console.log(err))
		}else{
			this.setState({
				errorAbonado:'Debe ingresarse un numero de abonado',
				location:{...this.state.location,piso:"",barrio:"",address:"",number:""},
				contacts:{...this.state.contacts,nombre:"",email:"",whatsapp:""}
			})
		}
		
	} 

	getTimes(){
		let fecha = this.state.date.format("YYYY-MM-DD");
		fetch(`${endpoint}/tasks/times/${fecha}/${this.state.vehicle}`)
				.then(res => res.json())
				.then(
					(result) => {
							this.setState({calendar:result})
							if (result.length===0){
								this.setState({
									time: { begin: "08:00", end: "09:00" }
								})
							} else {
								let tiempos = result.map((time) => {
									return moment(time.time.end,'HH:mm');
								})
							let m = moment.max(tiempos);
							let s = m._i.split(':');
							let s2 = `${s[0]}.${s[1]}`;
							let s3 = parseInt(s2);
							s3 = s3 + 1;
							let s4 = `${s3.toString()}:${s[1]}`;
								this.setState({
									time: { begin: m._i, end: s4 }
								 });
							}
					 }
				)
				.catch(err => console.log(err))
	}

	componentDidMount(){
		this.getVehicles();
		this.setState({socketData:socketIOClient(endpointSocketData)});
		this.setState({socketScheduled:socketIOClient(endpointSocketScheduled)});
	}

	componentDidUpdate(prevProps,prevState){
		if (this.state.vehicle !== prevState.vehicle){
			this.getTimes();
		}
		if (this.state.date !== prevState.date ) {
			this.getTimes();
		}
		if(this.state.duration!==prevState.duration||this.state.time.begin!==prevState.time.begin){
			let hour = this.state.time.begin.split(':');
			let h = parseInt(hour[0]); let m = parseInt(hour[1]);
			let begin = this.state.duration.split(':');
			let beginh = parseInt(begin[0]);
			let beginm = parseInt(begin[1]);
			h = h + beginh;
			m = m + beginm;
			if (m>59){ m = m - 60 }
			if (h>23){ h = h - 24 }
			h = h.toString();
			m = m.toString();
			let mm;
			let hh;
			if (m<10){ mm = "0".concat(m)
			} else {
				mm = m;
			}
			if (h<10) {
				hh = "0".concat(h);
			} else {
				hh = h;
			}
			let hora = hh.concat(":").concat(mm);
			this.handleChangeTimeEnd(hora);
		}
		/*
		if(this.prevState.socketData!==this.state.socketData){

		}
		if(this.prevState.socketScheduled!==this.state.socketScheduled){

		}
		*/
	}

	handleSubmitAll(){

		let url = `${endpoint}/tasks`;
		let fecha = this.state.date.format("YYYY-MM-DD");

		let {email,nombre,whatsapp} = this.state.contacts;
		let {city,address,number,piso,barrio,link} = this.state.location;
		
		let time = {
			begin: "25:00",
			end: "26:00"
		}

		let scheduled = false;
		if(this.state.scheduled==="true"){
			scheduled = true;
			time.begin = this.state.time.begin;
			time.end = this.state.time.end;
		}

		var data = {
			creator: localStorage.getItem('email'),
			vehicle: this.state.vehicle,
			technicians: this.state.selects,
			type: this.state.type,
			abonado: this.state.abonado,
			images : this.state.images,
			scheduled,
			location: {
				city: city.trim(),
				address: address.trim(),
				number: number.trim(),
				piso: piso.trim(),
				barrio: barrio.trim(),
				link: link.trim()
			},
			contacts: {
				email: email.trim(),
				nombre: nombre.trim(),
				whatsapp: whatsapp.trim()
			},
			date: fecha,
			time,
			observations: this.state.observations.trim()
		};

		const technicians = this.state.selects;
		if(technicians.size>0){
			if(city!=="SELECCIONA UNA"){
				this.setState({ buttonEnable: false });
				fetch(url, {
					method: 'POST',
					body: JSON.stringify(data),
					headers:{	'Content-Type': 'application/json','authorization':localStorage.getItem('token')	}
				}).then(res => res.json())
				.then(result => {
					if (result.success===true) {
						const day = moment().format("YYYY-MM-DD");
						if(this.state.date.format("YYYY-MM-DD")===day){
							this.state.socketData.emit("initial_data",day);
							this.state.socketScheduled.emit("initial_data_scheduled",this.state.scheduled);
						}
						this.props.history.push(toHome);
						this.setState({buttonEnable:true});
					}
				})
				.catch(error => {
					this.setState({ error : error });
				});
			}else{
				this.setState({errorForm:'debes seleccionar una localidad'});
			}
		}else{
			this.setState({errorForm:'debe agregar un tecnico a cargo de la tarea'})
		}

	}

	componentWillUnmount(){
		this.state.socketData.close();
		this.state.socketScheduled.close();
	}

	render(){
		const {isLoaded,error,buttonEnable,scheduled} = this.state;
		if(error){
			return <MsgError message={error.message}/>
		}else {
			if(!isLoaded){
				return <Loading/>
			}
			return(
					<main className="newtask">

							<div className="newtask-aside">
								<Aside/>
							</div>

							<div className="newtask-body">

								<div className="card">
									<div className="card-header">
										<h2>Crear una nueva tarea</h2>
									</div>
									<div className="card-body">

										<div className="form-group">
											<label>Tipo de planificación</label>
											<select className="form-control" value={this.state.scheduled} onChange={(e)=>this.handleChangeScheduled(e.target.value)}>
												<option value={"true"}>CON HORARIO</option>
												<option value={"false"}>SIN HORARIO</option>
											</select>
										</div>

										<div className="form-row mb-2">
											<div className="form-group col-md-4">
												<label>Vehículos</label>
												<select className="form-control" value={this.state.vehicle} onChange={(e)=>this.handleChangeVehicle(e.target.value)}>
													{this.state.vehicles.map((v) => {
														return (<option value={v.name} key={v._id}>{v.name}</option>)
													})}
												</select>
											</div>
											<div className="form-group col-md-4">
												<label>Tipo de tarea</label>
												<select className="form-control" value={this.state.type} onChange={(e)=>this.handleChangeType(e.target.value)}>
													{this.state.tasks.map((v) => {
														return (<option value={v.name} key={v._id}>{v.name}</option>)
													})}
												</select>
											</div>
										</div>

										<div className="table-responsive mb-4" style={{"width": "100%","display":"flex","justifyContent":"center"}}>
												<DayPickerSingleDateController
														date={this.state.date}
														onDateChange={date => this.setState({date})}
														focused={this.state.focused}
														onFocusChange={({focused}) => this.setState({focused})}
												/>
										</div>
										
										{(scheduled==="true") ? 
											(<div className="form-row mb-2">
											<Hour	label={"Duración de la tarea"}	time={this.state.duration}	onSelectTime={this.handleChangeDuration.bind(this)}/>
											<Hour	label={"Hora de comienzo"}	time={this.state.time.begin}	onSelectTime={this.handleChangeTimeBegin.bind(this)}	/>
											<Hour	label={"Hora de finalización"}	time={this.state.time.end}	onSelectTime={this.handleChangeTimeEnd.bind(this)}	/>
											</div>) 
										: (null) }

										<div className="form-row mb-2 d-flex justify-content-center align-items-end">
											<div className="col-md-6">
												<label>Técnicos a cargo</label>
												<select className="form-control" value={this.state.technician} onChange={(e)=>this.handleChangeTechnician(e.target.value)}>
														{this.state.technicians.map((t,i) => {
															return <option key={i} value={t.name}>{t.name}</option>
														})}
												</select>

											</div>
											<div className="col-md-6 mt-2">
												<button className="btn btn-secondary" onClick={()=>this.handleSelect({technician:this.state.technician})}>Seleccionar</button>
											</div>
											<div className="col-md-12 p-2">
												<ul className="list-group">
													{this.state.selects.map((t,i) => {
														return <li className="list-group-item" key={i}> <p>{t}</p> <button className="btn btn-danger" onClick={(e)=>this.handleDelete(i,e)}>Eliminar</button></li>
													})}
												</ul>
											</div>
										</div>

										<div className="form-row d-flex justify-content-center align-items-center mb-2">
											<Input placeholder="Ingrese el numero de abonado" label={"Abonado"} value={this.state.abonado} handleChange={this.handleChangeAbonado.bind(this)}/>
											<div className="col-md-6">
												<button className="btn btn-primary" onClick={()=>this.buscarAbonado()}>Buscar</button>
											</div>
											{this.state.errorAbonado ? <div className="alert alert-danger">{this.state.errorAbonado}</div> : null }
										</div>

										<div className="form-row mb-2">

										<Input placeholder="Nombre del cliente" label={"Nombre del cliente"} value={this.state.contacts.nombre} handleChange={this.handleChangeNombre.bind(this)}/>

										<Input placeholder={"San Martin"}	label={"Calle/s"}	value={this.state.location.address}	handleChange={this.handleChangeLocationAddress.bind(this)}	/>

										<Input placeholder={"445"}	label={"Número"}	value={this.state.location.number}	handleChange={this.handleChangeNumero.bind(this)}	/>

										<div className="form-group col-md-6">
											<label>Localidad</label>
											<select className="form-control" value={this.state.location.city} onChange={(e)=>this.handleChangeLocationCity(e.target.value)}>
												{this.state.locations.map((v,i) => {
													return (<option value={v} key={i}>{v}</option>)
												})}
											</select>

										</div>

										<Input placeholder="Piso" label={"Depto."} value={this.state.location.piso} handleChange={this.handleChangePiso.bind(this)}/>
										<Input placeholder="Barrio" label={"Barrio"} value={this.state.location.barrio} handleChange={this.handleChangeBarrio.bind(this)}/>


										</div>


										<div className="form-row">
											<Input placeholder={"alguien@gmail.com"}	label={"Email"}	value={this.state.contacts.email}	handleChange={this.handleChangeContactsEmail.bind(this)}	/>
											<Input placeholder={"2221000000"}	label={"Número de teléfono"}	value={this.state.contacts.whatsapp}	handleChange={this.handleChangeContactsWhatsapp.bind(this)}	/>

										</div>
										<TextArea	label={"Observaciones"}	value={this.state.observations}	handleChange={this.handleChangeObservations.bind(this)}	/>
									</div>
									<div className="card-footer">
										{buttonEnable ? (
											<React.Fragment>
												<button className="btn btn-primary" onClick={()=>this.handleSubmitAll()}>Crear tarea</button>
											</React.Fragment>
										):(
											<React.Fragment>
												<button className="btn btn-primary" disabled>Crear tarea</button>
											</React.Fragment>
										)}
										{this.state.errorForm ? <div className="alert alert-danger mt-2">{this.state.errorForm}</div> : null }
									</div>
								</div>
							</div>
					</main>
			);
		}
	}
}

export default NewTask;
