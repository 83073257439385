import React from 'react';

function Hour(props){
  return(
    <div className="form-group col-md-4">
        <label>{props.label}</label>
        <input className="form-control" type="time" value={props.time} onChange={(e)=>props.onSelectTime(e.target.value)}/>
    </div>
  );
}

export default Hour;
