import React from 'react';

export default function NewLocation(props) {
    return (
        <div className="card mt-4">
            <div className="card-header">
                <h2>Agregar localidad</h2>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="vehicle">Nueva localidad</label>
                    <input id="vehicle" className="form-control" type="text" value={props.location} onChange={props.setLocation.bind(this)}/>
                </div>
                  
                <div className="form-group col-md-4">
                    <label>Zona</label>
                    <select className="form-control" value={props.zone.name} onChange={(e)=>props.handleChangeZone(e.target.value)}>
                      {props.zones.map((v,i) => {
                        return (<option value={v.name} key={i}>{v.name}</option>)
                      })}
                    </select>
                </div>  

                {props.error ? (<div className="alert alert-danger">{props.error}</div>) : null }
            </div>
            <div className="card-footer">
                  {props.buttonEnable ? (
                    <button className="btn btn-primary" onClick={props.handleSubmit.bind(this)}>Añadir localidad</button>
                  ):(
                    <button className="btn btn-primary" disabled>Añadir localidad</button>
                  )}
            </div>
        </div>
    );
}