export let initial_state = {
    vehicles : [],
	locations: [],
	zones: [],
	isLoaded: false,
	error: null,
	focused: null,
	data: [],
	data2: [],
	isChecked: false,
	loading: true
}