import React from 'react';

function Loading(){
  return(
    <section className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <main className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </main>
    </section>
  );
}

export default Loading;
