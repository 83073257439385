export default {
    tasktypes: [],
    tasktypeslastweek: [],
    vehicles: [],
    vehicleslastweek: [],
    clients: [],
    clientslastweek: [],
    creators: [],
    creatorslastweek: [],
    technicians: [],
    technicianslastweek: [],
    error: null,
    isLoaded: false
}