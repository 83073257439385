import React from 'react';

export default function canceledRedport(props){
    if(props.canceled_at){
        return(
            <div className="card">
				<div className="card-header">
				    <h2>Informe</h2>
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<table className="table" id="tablon_service">
							<tbody>
								<tr>
									<td>
										{props.canceled_info}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			    <div className="card-footer">
			    </div>
			</div>
        );
    }
    return null;
}