import React,{ Component } from 'react';
import { endpoint } from '../../config';
import {fromJS,Map} from 'immutable';
import Loading from '../../components/Loading';

import Aside from '../../components/Aside';


class Technicians extends Component {

  state = {
    technician: '', technicians: fromJS([]),
    error: null,
    isLoaded: true,
    buttonEnable: true
  };

  getTechnicians(){
    fetch(`${endpoint}/technicians`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({technicians: fromJS(result),isLoaded:true});
        }
      )
      .catch(err => console.log(err))
  }

  componentDidMount(){
    this.getTechnicians();
  }

  setTechnician(e){
    this.setState({technician: e.target.value});
  }

  handleSubmit(e){
    if (this.state.technician!==''){
      let url = `${endpoint}/technicians`;
      let token = localStorage.getItem('token');
  		var data = { name: this.state.technician.toUpperCase().trim(), created_by: localStorage.getItem('email') };
      this.setState({buttonEnable:false});
  		fetch(url, {
  		  method: 'POST',
  		  body: JSON.stringify(data),
  		  headers:{ 'Content-Type': 'application/json', 'authorization':`${token}`  }
  		}).then(res => res.json())
  		.then(result => {
        this.setState({buttonEnable:true,technicians:this.state.technicians.push(Map({name:result.name,_id:result.id})),technician:'',error:null})
  		})
  		.catch(error => console.error('Error:', error));
    } else {
      this.setState({error:'Debes ingresar un nombre'});
    }
  }

  deleteTechnician({i,id},e){
    let token = localStorage.getItem('token');
    this.setState({buttonEnable:false});
    fetch(`${endpoint}/technicians`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json','authorization':`${token}`},
      body: JSON.stringify({id})
    })
    .then(res => res.json())
    .then(res => {
      this.setState({buttonEnable:true,technicians:this.state.technicians.delete(i)});
    })
  }

  render(){
    const {isLoaded,buttonEnable} = this.state;
    if (!isLoaded){
      return(<Loading/>);
    }else {
      return(
          <main className="newtask">
            <div className="newtask-aside">
              <Aside/>
            </div>
            <div className="newtask-body">

              <div className="card">
                <div className="card-header">
                  <h2>Lista de técnicos</h2>
                </div>
                <div className="card-body">

                {(this.state.technicians.size>0) ? (
                  <ul className="list-group">
                    {this.state.technicians.map((v,i) => {
                      return (
                        <li className="d-flex justify-content-between align-items-center list-group-item" key={i}>
                          <p>{v.get('name')}</p>
                          {buttonEnable ? (
                            <button className="btn btn-danger" onClick={(e)=>this.deleteTechnician({i,id:v.get('_id')},e)}>Eliminar</button>
                          ):(
                            <button className="btn btn-danger" disabled>Eliminar</button>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                ):(
                  <ul className="list-group">
                    <li className="d-flex justify-content-between align-items-center list-group-item">No hay técnicos</li>
                  </ul>
                )}
                </div>
              </div>

              <div className="card mt-4">
                <div className="card-header">
                  <h2>Agregar técnico</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="vehicle">Nombre del técnico</label>
                    <input id="vehicle" className="form-control" type="text" value={this.state.technician} onChange={this.setTechnician.bind(this)}/>
                  </div>
                  {this.state.error ? (<div className="alert alert-danger">{this.state.error}</div>) : null }
                </div>
                <div className="card-footer">
                  {buttonEnable ? (
                    <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Añadir técnico</button>
                  ):(
                    <button className="btn btn-primary" disabled>Añadir Técnico</button>
                  )}
                </div>
              </div>

            </div>
          </main>
      );
    }

  }
}

export default Technicians;
