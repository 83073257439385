import React from 'react';

function TextArea(props){
  return(
    <fieldset className="form-group">
        <label>{props.label}</label>
        <textarea className="form-control" value={props.value} onChange={(e)=>props.handleChange(e.target.value)}/>
    </fieldset>
  );
}

export default TextArea;
