import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import AppContext from '../context/app-context';

function BtnSignout(){
  const context = useContext(AppContext);
  let history = useHistory();
  let from = {pathname:'/'};
  
  let chau = () => {
    context.setAuth(false);
    history.push('/');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    history.replace(from);
  }

  return(
    <button className="btn btn-outline-primary" onClick={chau.bind(this)}>Cerrar sesión</button>
  );
}

export default BtnSignout;
