export default {
    error: null,
	isLoaded: false,
	data: {},
	buttonEnable: true,
	show: false,
	show2: false,
	show3: false,
	kit: [],
	kitSelected: 'NO CORRESPONDE',
	kitPower: '',
	observations: '',
	errorModalCancel: null
}