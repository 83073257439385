import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';
import Chart from '../../components/Chart';
import initial_state from './initial_state';

class Statistic extends Component {

  state = { ...initial_state };

  getStatisticTasktypes(){
    fetch(`${endpoint}/statistic/tasktypes`)
    .then(res => res.json())
    .then( (result) => this.setState({tasktypes: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticTasktypesLastweek());
  }

  getStatisticTasktypesLastweek(){
  	fetch(`${endpoint}/statistic/tasktypes/lastweek`)
    .then(res => res.json())
    .then( (result) => this.setState({tasktypeslastweek: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticVehicles());
  }

  getStatisticVehicles(){
  	fetch(`${endpoint}/statistic/vehicles`)
    .then(res => res.json())
    .then( (result) => this.setState({vehicles: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticVehiclesLastweek());
  }

  getStatisticVehiclesLastweek(){
  	fetch(`${endpoint}/statistic/vehicles/lastweek`)
    .then(res => res.json())
    .then( (result) => this.setState({vehicleslastweek: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticClients());
  }

  getStatisticClients(){
  	fetch(`${endpoint}/statistic/clients`)
    .then(res => res.json())
    .then( (result) => this.setState({clients: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticClientsLastweek());
  }

  getStatisticClientsLastweek(){
  	fetch(`${endpoint}/statistic/clients/lastweek`)
    .then(res => res.json())
    .then( (result) => this.setState({clientslastweek: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticCreators());
  }

  getStatisticCreators(){
  	fetch(`${endpoint}/statistic/creators`)
    .then(res => res.json())
    .then( (result) => this.setState({creators: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticCreatorsLastweek());
  }

  getStatisticCreatorsLastweek(){
  	fetch(`${endpoint}/statistic/creators/lastweek`)
    .then(res => res.json())
    .then( (result) => this.setState({creatorslastweek: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticTechnicians());
  }

  getStatisticTechnicians(){
  	fetch(`${endpoint}/statistic/technicians`)
    .then(res => res.json())
    .then( (result) => this.setState({technicians: result}))
    .catch(err => this.setState({error:err}))
    .then(()=>this.getStatisticTechniciansLastweek());
  }

  getStatisticTechniciansLastweek(){
  	fetch(`${endpoint}/statistic/technicians/lastweek`)
    .then(res => res.json())
    .then( (result) => this.setState({technicianslastweek: result,isLoaded:true}))
    .catch(err => this.setState({error:err}))
  }



  componentDidMount(){ this.getStatisticTasktypes() }

  render(){
    const {isLoaded} = this.state;
    if (!isLoaded){
      return(<Loading/>);
    }else {
      return(
          <main className="newtask">
            <div className="newtask-aside">
              <Aside/>
            </div>
            <div className="newtask-body">

              <div className="card">
                <div className="card-header">
                  <h2>Estadísticas</h2>
                </div>
                <div className="card-body">

                <Chart title={"Tipos de tarea más utilizadas"} data={this.state.tasktypes}/>
                <Chart title={"Tipos de tarea más utilizadas en la última semana"} data={this.state.tasktypeslastweek}/>
               	
               	<Chart title={"Vehículos más utilizados"} data={this.state.vehicles}/>
               	<Chart title={"Vehículos más utilizados en la última semana"} data={this.state.vehicleslastweek}/>

               	<Chart title={"Clientes con más tareas asignadas"} data={this.state.clients}/>
               	<Chart title={"Clientes con más tareas asignadas en la última semana"} data={this.state.clientslastweek}/>

               	<Chart title={"Usuarios con más tareas creadas"} data={this.state.creators}/>
               	<Chart title={"Usuarios con más tareas creadas en la última semana"} data={this.state.creatorslastweek}/>

               	<Chart title={"Técnicos con más tareas asignadas"} data={this.state.technicians}/>
               	<Chart title={"Técnicos con más tareas asignadas en la última semana"} data={this.state.technicianslastweek}/>

                </div>
              </div>

              

            </div>
          </main>
      );
    }

  }
}

export default Statistic;
