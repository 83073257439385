import React from 'react';

export default function VehiclesList(props){
    return(
    <div className="card">
		<div className="card-header">
			<h5>Vehículos sin utilizar</h5>
		</div>
		<div className="card-body">
			<ul className="list-group">
				{props.vehicles.map( v => {
					let encontrado = false;
					for (let i=0;i<props.data.length;i++)if(props.data[i]._id===v.name) encontrado = true;
					for (let i=0;i<props.data2.length;i++) if(props.data2[i]._id===v.name) encontrado = true;
					if (!encontrado) {
						return ( <li key={v._id} className="list-group-item"><h5>{v.name}</h5></li> )
					}else {
						return null;
					}
				})}
			</ul>
		</div>
	</div>
    );
}