import React,{ Component } from 'react';
import { endpoint } from '../../config';
import {fromJS,Map} from 'immutable';
import Loading from '../../components/Loading';

import Aside from '../../components/Aside';

class Vehicles extends Component {
  state = {
    vehicle: '',vehicles: fromJS([]),
    error: null,
    isLoaded: false,
    buttonEnable: true
  };

  setVehicle(e){ this.setState({ vehicle: e.target.value }) }

  getVehicles(){
    fetch(`${endpoint}/vehicles`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({vehicles: fromJS(result),isLoaded:true});
        }
      )
      .catch(err => console.log(err))
  }

  componentDidMount(){
    this.getVehicles();
  }

  handleSubmit(e){
      if(this.state.vehicle!==""){
        let url = `${endpoint}/vehicles`;
        var data = { name: this.state.vehicle.toUpperCase().trim(), created_by: localStorage.getItem('email') };
        let token = localStorage.getItem('token');
        this.setState({buttonEnable:false});
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers:{ 'Content-Type': 'application/json', 'authorization': `${token}`  }
        }).then(res => res.json())
        .then(result => {
          this.setState({buttonEnable:true,vehicles:this.state.vehicles.push(Map({name:result.name,_id:result.id})),vehicle:'',error:null})
        })
      .catch(error => console.error('Error:', error));
      }else{
        this.setState({error:"Debes ingresar un nombre de vehículo"});
      }
  }

  deleteVehicle({i,id},e){
    let token = localStorage.getItem('token');
    this.setState({buttonEnable:false});
    fetch(`${endpoint}/vehicles`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json','authorization':`${token}`},
      body: JSON.stringify({id})
    })
    .then(res => res.json())
    .then(res => {
      this.setState({buttonEnable:true});
      this.setState({vehicles:this.state.vehicles.delete(i)});
    })
    .catch(err => console.log(err))
  }

  render(){
    const {isLoaded,buttonEnable} = this.state;
    if (!isLoaded){
      return(<Loading/>);
    }else {
      return(
          <main className="newtask">

            <div className="newtask-aside">
              <Aside/>
            </div>

            <div className="newtask-body">

              <div className="card">
                <div className="card-header">
                  <h2>Lista de vehículos</h2>
                </div>
                <div className="card-body">

                {(this.state.vehicles.size>0) ? (
                  <ul className="list-group">
                    {this.state.vehicles.map((v,i) => {
                      return (
                        <li className="d-flex justify-content-between align-items-center list-group-item" key={i}>
                          <p>{v.get('name')}</p>
                          {buttonEnable ? (
                            <button className="btn btn-danger" onClick={(e)=>this.deleteVehicle({i,id:v.get('_id')},e)}>Eliminar</button>
                          ):(
                            <button className="btn btn-danger" disabled>Eliminar</button>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                ):(
                  <ul className="list-group">
                    <li className="d-flex justify-content-between align-items-center list-group-item">No hay vehículos</li>
                  </ul>
                )}
                </div>
              </div>

              <div className="card mt-4">
                <div className="card-header">
                  <h2>Agregar vehículo</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="vehicle">Nuevo vehículo</label>
                    <input id="vehicle" className="form-control" type="text" value={this.state.vehicle} onChange={this.setVehicle.bind(this)}/>
                  </div>
                  {this.state.error ? (<div className="alert alert-danger">{this.state.error}</div>) : null }
                </div>
                <div className="card-footer">
                  {buttonEnable ? (
                    <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Añadir vehículo</button>
                  ):(
                    <button className="btn btn-primary" disabled>Añadir vehículo</button>
                  )}
                </div>
              </div>

            </div>
          </main>
      );
    }

  }
}

export default Vehicles;
