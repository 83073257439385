import React from 'react';

export default function Info(){
    return(
    <ul className="monitor-footer">
        <li className="monitor-footer-item">
            <svg height="100" width="100">
                <circle cx="50" cy="50" r="20" stroke="white" stroke-width="1" fill="#d9534f"/>
            </svg> 
            TAREAS RETRASADAS
        </li>
        <li className="monitor-footer-item">
            <svg height="100" width="100">
                <circle cx="50" cy="50" r="20" stroke="white" stroke-width="1" fill="#f0ad4e"/>
            </svg> 
            TAREAS PENDIENTES
        </li>
        <li className="monitor-footer-item">
            <svg height="100" width="100">
                <circle cx="50" cy="50" r="20" stroke="white" stroke-width="1" fill="#5cb85c"/>
            </svg> 
            TAREAS COMPLETADAS
        </li>
        <li className="monitor-footer-item">
            <svg height="100" width="100">
                <circle cx="50" cy="50" r="20" stroke="white" stroke-width="1" fill="#5bc0de"/>
            </svg> 
            TAREAS COMPLETADAS SIN TERMINAR
        </li>
        <li className="monitor-footer-item">
            <svg height="100" width="100">
            <circle cx="50" cy="50" r="20" stroke="white" stroke-width="1" fill="#292b2c"/>
            </svg> 
            TAREAS CANCELADAS
        </li>
        <li className="monitor-footer-item">
            <svg width="100" height="100">
            <rect x="32" y="32" width="36" height="36" stroke="white" stoke-width="1" fill="#FFF"/>
            </svg>
            TAREAS CON HORARIO
        </li>
        <li className="monitor-footer-item">
            <svg width="100" height="100">
            <rect x="32" y="32" width="36" height="36" stroke="white" stoke-width="1" fill="#292b2c"/>
            </svg>
            TAREAS SIN HORARIO
        </li>
    </ul>
    );
}