import React,{	Component	} from 'react';
import moment from 'moment';
import {	endpoint	} from '../../config';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';

import { DayPickerSingleDateController } from 'react-dates';

class TimesVehicle extends Component {

	state = {
    vehicle: '',vehicles: [],
		tasks:[],notasks:[],
		date: moment(),
		error: null,
		isLoaded: false
	}

	handleChangeDate(date){	this.setState({	date	})}
	handleChangeVehicle(e){ this.setState({ vehicle: e.target.value }) }

  getVehicles(){
    fetch(`${endpoint}/vehicles`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({vehicles: result, vehicle: result[0].name});
        }
      ).then(()=>this.getTimes())
			.catch(err => console.log(err))
  }

	getTimes(){
		let fecha = this.state.date.format("YYYY-MM-DD");
    let vehicle = this.state.vehicle;
		fetch(`${endpoint}/tasks/times/${fecha}/${vehicle}`)
				.then(res => res.json())
				.then(response => {
          let info = [];
          if (response.length>0) {
            let i=0;
            let length = response.length;
            let hora = "08:00";
            for (i;i<length;i++){
              let time = {begin:'',end:''};
              if (hora!==response[i].time.begin){
                time.begin = hora;
                time.end = response[i].time.begin;
                info.push(time);
              }
              hora = response[i].time.end;
            }
            hora = "21:00"
            if (response[length-1].time.end<hora){
              let time = { begin: response[length-1].time.end , end: hora }
              info.push(time);
            }

          }else {
            info.push({begin:"08:00",end:"21:00"});
          }
          this.setState({isLoaded:true,tasks:response,notasks:info});
        })
				.catch(err => console.log(err))
	}

	componentDidMount(){
		this.getVehicles();
	}

	componentDidUpdate(prevProps,prevState){
		if (this.state.date !== prevState.date ) {
			this.getTimes();
		}
    if (prevState.vehicle !== this.state.vehicle ){
      this.getTimes();
    }
	}

	render(){
		const {isLoaded,error} = this.state;
		if(error){
			return (
				<section>
					<main>
						Ups: {error.message}
					</main>
				</section>
			);
		}else {
			if(!isLoaded){
				return <Loading/>
			}
			return(
					<main className="newtask">

							<div className="newtask-aside">
								<Aside/>
							</div>

							<div className="newtask-body">
								<div className="card">
									<div className="card-header">
										<h2>Horarios del vehículo</h2>
									</div>
									<div className="card-body">
									<div className="table-responsive" style={{"width": "100%"}}>
  										<DayPickerSingleDateController
  												date={this.state.date}
  												onDateChange={date => this.setState({date})}
  												focused={this.state.focused}
  												onFocusChange={({focused}) => this.setState({focused})}
  										/>
  									</div>

                      <select className="mt-4" value={this.state.vehicle} onChange={this.handleChangeVehicle.bind(this)}>
                        {this.state.vehicles.map( v => {
                          return <option key={v._id} value={v.name}>{v.name}</option>
                        })}
                      </select>

											<div className="card mt-4">
                      	<div className="card-header">
													<h3>Horarios ocupados</h3>
												</div>
												<div className="card-body">
		                      <ul className="list-group">
		                      { this.state.tasks.map( (t,i) => {
		                          return <li key={i} className="list-group-item">{t.time.begin} - {t.time.end}</li>
		                        })
		                      }
		                      </ul>
												</div>
											</div>

											<div className="card">
												<div className="card-header">
													<h3>Horarios disponibles</h3>
												</div>
												<div className="card-body">
	                      	<ul className="list-group">
	                        	{this.state.notasks.map( (p,q) => {
	                          	return <li key={q} className="list-group-item">{p.begin} - {p.end}</li>
	                        	} )}
	                      	</ul>
												</div>
											</div>

                  </div>
								</div>
							</div>
					</main>
			);
		}
	}
}


export default TimesVehicle;
