import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';
import { Link } from 'react-router-dom';
import initial_state from './initial_state';

class Delayed extends Component {

  constructor(){
      super()
      this.state = { ...initial_state }
  }
  
  fetchTasksDelayed(){
    this.setState({isLoaded:false})
    fetch(`${endpoint}/tasks/delayed`,{headers:{
        'Content-Type': 'application/json','authorization': `${localStorage.getItem('token')}`
    }})
    .then(res => res.json())
    .then( (result) => this.setState({ isLoaded: true, tasks: result }))
    .catch(err => console.log(err))
  }

  componentDidMount(){
    //para estar retrasada la hora de finalizacion de la tarea tiene que ser menor a la hora actual
    //ej finaliza a las 11:36 y son las 11:37 esta retrasada
    this.fetchTasksDelayed();
  }
  
  render(){
    let {isLoaded} = this.state;
    if(!isLoaded){ return <Loading/>
    }else{
        return(
            <main className="newtask">
                <div className="newtask-aside">
                    <Aside/>
                </div>
                <div className="newtask-body">

                    <div className="card">
                        <div className="card-header">
                            <h2>Tareas retrasadas</h2>
                        </div>
                        <div className="card-body">
                            <ul className="list-group mt-4">
                            {this.state.tasks.map((t)=> {
                                return (
                                    <Link key={t._id} to={`/roadmap/${t._id}`} className="list-group-item list-group-item-action">
                                        <div className="taskitem-header">
                                            <h5 className="mb-1 text-dark">{t.type}</h5>
                                        </div>
                                        <div className="list-group list-group-horizontal mt-2 text-light mb-2">
                                            <li className="list-group-item text-dark">{t.contacts.nombre}</li>
                                        </div>
                                        <p className="mb-1 text-dark">{t.location.city} / {t.location.address}</p>
                                        <small className="text-dark">{t.time.begin} - {t.time.end} hs.</small>
                                        <ul className="list-group mt-2 text-light">{t.technicians.map((t,i) => {
                                        return <li key={i} className="list-group-item bg-dark text-white">{t}</li>
                                        })}</ul>
                                    </Link>
                                )
                            })}
                            </ul>
                        </div>
                    </div>

                </div>
            </main>
        );
    }
  }
}

export default Delayed;