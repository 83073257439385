import React from 'react';

import logoVehicles from '../images/truck-pickup-solid.svg';
import logoEquipos from '../images/hdd-solid.svg';
import logoLocalidades from '../images/map-marker-alt-solid.svg';
import logoTecnicos from '../images/users-cog-solid.svg';
import logoCuentas from '../images/user-edit-solid.svg';
import logoTareas from '../images/clipboard-regular.svg';
import logoNuevaTarea from '../images/plus-circle-solid.svg';
import logoHorariosVehiculos from '../images/calendar-week-solid.svg';
import logoSearch from '../images/search.svg';
import logoClients from '../images/customers.svg';
import logoStatistics from '../images/statistics.svg';
import logoMonitor from '../images/computadora.svg';
import logoDelayedTask from '../images/delayed.svg';
import logoUpAudio from '../images/upaudio.svg';
import logoZones from '../images/globo-terraqueo.svg';
import {Link} from 'react-router-dom';

function Aside() {
  if (localStorage.getItem('role')==='root'){
    return(
      <div className="card mt-4">
        <div className="card-header">
          Opciones generales
        </div>
        <div className="card-body">
          <ul className="list-group">
            <Link to={"/newtask"} className="list-group-item"><img src={logoNuevaTarea} width={30} height={30} className="mr-2" alt="logo add"/>Nueva tarea</Link>
            <Link to="/times" className="list-group-item"><img src={logoHorariosVehiculos} width={30} height={30} className="mr-2" alt="logo hour vehicle"/>Horarios vehículo</Link>
            <Link to="/search" className="list-group-item"><img src={logoSearch} width={30} height={30} className="mr-2" alt="logo hour vehicle"/>Búsqueda</Link>
            <Link to="/monitor" className="list-group-item"><img src={logoMonitor} width={30} height={30} className="mr-2" alt="logo monitor"/>Monitor</Link>
            <Link to="/delayed" className="list-group-item"><img src={logoDelayedTask} width={30} height={30} className="mr-2" alt="logo monitor"/>Tareas retrasadas</Link>
          </ul>
        </div>
        <div className="card-header">Editar</div>
        <div className="card-body">
          <ul className="list-group">
            <Link to="/vehicles" className="list-group-item"><img src={logoVehicles} width={30} height={30} className="mr-2" alt="logo vehicles"/>Vehículos</Link>
            <Link to="/technicians" className="list-group-item"><img src={logoTecnicos} width={30} height={30} className="mr-2" alt="logo tecnicos"/>Técnicos</Link>
            <Link to="/kit" className="list-group-item"><img src={logoEquipos} width={30} height={30} className="mr-2" alt="logo kits"/>Equipos</Link>
            <Link to="/locations" className="list-group-item"><img src={logoLocalidades} width={30} height={30} className="mr-2" alt="logo locations"/>Localidades</Link>
            <Link to="/zones" className="list-group-item"><img src={logoZones} width={30} height={30} className="mr-2" alt="logo zones"/>Zonas</Link>
            <Link to="/tasks" className="list-group-item"><img src={logoTareas} width={30} height={30} className="mr-2" alt="logo tasktypes"/>Tipos de tarea</Link>
            <Link to="/accounts" className="list-group-item"><img src={logoCuentas} width={30} height={30} className="mr-2" alt="logo accounts"/>Cuentas</Link>
            <Link to="/clients" className="list-group-item"><img src={logoClients} width={30} height={30} className="mr-2" alt={"logo clientes"}/>Clientes</Link>
            <Link to="/statistic" className="list-group-item"><img src={logoStatistics} width={30} height={30} className="mr-2" alt={"logo clientes"}/>Estadísticas</Link>
            <Link to="/upaudio" className="list-group-item"><img src={logoUpAudio} width={30} height={30} className="mr-2" alt={"logo upload sound"}/>Audio</Link>
          </ul>
        </div>
      </div>
    );
  }else if(localStorage.getItem('role')==='admin'){
    return (
      <div className="card mt-4">
        <div className="card-header">
          Opciones generales
        </div>
        <div className="card-body">
          <ul className="list-group">
            <Link to={"/newtask"} className="list-group-item"><img src={logoNuevaTarea} width={30} height={30} className="mr-2" alt="logo add"/>Nueva tarea</Link>
            <Link to="/times" className="list-group-item"><img src={logoHorariosVehiculos} width={30} height={30} className="mr-2" alt="logo hour vehicle"/>Horarios vehículo</Link>
            <Link to="/search" className="list-group-item"><img src={logoSearch} width={30} height={30} className="mr-2" alt="logo hour vehicle"/>Búsqueda</Link>
            <Link to="/clients" className="list-group-item"><img src={logoClients} width={30} height={30} className="mr-2" alt={"logo clientes"}/>Clientes</Link>
            <Link to="/monitor" className="list-group-item"><img src={logoMonitor} width={30} height={30} className="mr-2" alt="logo monitor"/>Monitor</Link>
            <Link to="/delayed" className="list-group-item"><img src={logoDelayedTask} width={30} height={30} className="mr-2" alt="logo monitor"/>Tareas retrasadas</Link>
          </ul>
        </div>
      </div>
    );
  }else{
    return null;
  }
}

export default Aside;
