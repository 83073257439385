const PUBLIC_VAPID_KEY='BDrOysnvNVVxW9LhzAz1TtPvf3MfcPNT_xFsuSEwJ1d4J9-iPqcJJfVS3Y_AJMsvJIDUWlh3K1iZSX9gx1tigLc';
//const ENDPOINT_NOTIFICATIONS='https://tracert-api.tk/subscribe';
const {endpointNotifications} = require("./config");
const ENDPOINT_NOTIFICATIONS=`${endpointNotifications}/subscribe`;

async function send(){
    //console.log('Registering service worker...');
    const register = await navigator.serviceWorker.register('./worker.js',{
        scope: '/'
    })
    //console.log('Service worker registered')

    //console.log('Registering push...');
    const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
    })
    //console.log('Push Registered');

    //console.log('Send Push');
    await fetch(ENDPOINT_NOTIFICATIONS,{
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            "Content-Type": "application/json"
        }
    })
    //console.log('Push sent...');

}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
   
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
   
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function registerSW(){
    if('serviceWorker' in navigator){
        send().catch(err=>console.log(err))
    }
}

export {registerSW};