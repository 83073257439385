import React,{Component} from 'react';
import { endpoint } from '../../config';
 
import Aside from '../../components/Aside';
import moment from 'moment';

import {Link} from 'react-router-dom';

import initial_state from './initial_state'

class Search extends Component {
	
	state = { ...initial_state }

	getTasks(name){
		this.setState({buttonEnabled:false})
	    fetch(`${endpoint}/tasks/contacts3/${name}`)
	    .then(res => res.json())
		.then((result) => {
			//console.log(result);
			this.setState({tasks: result,buttonEnabled:true})
		})
	    .catch(err => console.log(err))
  	}

  	getTasksById(id){
		this.setState({buttonEnabled:false})
	    fetch(`${endpoint}/tasks/contacts2/${id}`)
	    .then(res => res.json())
		.then((result) => this.setState({tasks: result,buttonEnabled:true}))
	    .catch(err => console.log(err))
  	}

	setSearchText(searchText){ this.setState({searchText}) }

	handleSubmit(e){
		e.preventDefault();
		isNaN(this.state.searchText) ? this.getTasks(this.state.searchText.trim()) : this.getTasksById(this.state.searchText.trim());
	}

	render(){
		let {searchText,tasks,buttonEnabled} = this.state;
		return(
		<main className="newtask">
			<div className="newtask-aside">
				<Aside/>
			</div>
			<div className="newtask-body">
			<form onSubmit={this.handleSubmit.bind(this)} className="input-group mb-3">
				<input type="text" className="form-control" placeholder="NOMBRE DEL CLIENTE O NÚMERO DE ABONADO" value={searchText} onChange={(e)=>this.setSearchText(e.target.value)} required/>
				<div className="input-group-append">
				{ buttonEnabled ? (<button className="btn btn-outline-secondary">Buscar</button>):(<button disabled className="btn btn-outline-secondary">cargando</button>)}
				</div>
			</form>
			{tasks.length>0 ? (
			<ul className="list-group">
				{tasks.map((t,i) => {
				return (
				<Link key={i} className="list-group-item list-group-item-action d-flex w-100 justify-content-between" to={`/roadmap/${t._id}`}><p>{moment(t.date).format("DD-MM-YYYY")}</p><p>{t.contacts.nombre}</p> <p>{t.type}</p></Link>		
				)
				})}
			</ul>
			):(
			<div className="card">
				<div className="card-body">
				No hay datos
				</div>
			</div>
			)
			}
			</div>
		</main>
		);
	}
}

export default Search;