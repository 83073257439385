export default {
    error: null,
	errorService: null,
    isLoaded: false,
    ssid: '',
    version: '',
    kit: '',
    ccq: '',
    signal: '',
    observations: '',
	db: '',
	mdrop: '',
	isChecked: false,
	buttonEnable: true
}