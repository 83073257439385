import React,{ Component } from 'react';
import { endpoint } from '../../config';
import {fromJS,Map} from 'immutable';
import Loading from '../../components/Loading';
import Aside from '../../components/Aside';
import initial_state from './initial_state';
import NewLocation from './NewLocation';

class Locations extends Component {

  state = { ...initial_state };

  setLocation(e){ this.setState({ location: e.target.value }) }
  handleChangeZone(zone){this.setState({zone})};

  getLocations(){
    fetch(`${endpoint}/locations`)
    .then(res => res.json())
    .then( (result) => this.setState({locations: fromJS(result)}))
    .then( () => this.getZones() )
    .catch(err => this.setState({error:err}))
  }

  getZones(){
    fetch(`${endpoint}/zones`)
    .then(res => res.json())
    .then( (result) => this.setState({zones: result,zone:result[0].name,isLoaded:true}))
    .catch(err => this.setState({error:err}))
  }

  componentDidMount(){ this.getLocations() }

  handleSubmit(e){
    if (this.state.location!==''){
      let url = `${endpoint}/locations`;
      let token = localStorage.getItem('token');
  		var data = { name: this.state.location.toUpperCase().trim(),zone: this.state.zone, created_by: localStorage.getItem('email') };
      this.setState({buttonEnable:false});
  		fetch(url, {
  		  method: 'POST',
  		  body: JSON.stringify(data),
  		  headers:{ 'Content-Type': 'application/json','authorization': `${token}`  }
  		}).then(res => res.json())
  		.then(result => this.setState({buttonEnable:true,locations:this.state.locations.push(Map({name:result.name,_id:result.id,zone:result.zone})),location:'',error:null}))
  		.catch(error => console.error('Error:', error));
    } else {
      this.setState({error:'Debes ingresar un nombre'});
    }
  }

  deleteLocation({i,id},e){
    let token = localStorage.getItem('token');
    this.setState({buttonEnable:false});
    fetch(`${endpoint}/locations`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json','authorization':`${token}`},
      body: JSON.stringify({id})
    })
    .then(res => res.json())
    .then(res => this.setState({buttonEnable:true,locations:this.state.locations.delete(i)}))
  }

  render(){
    const {isLoaded,buttonEnable} = this.state;
    if (!isLoaded){
      return(<Loading/>);
    }else {
      return(
          <main className="newtask">
            <div className="newtask-aside">
              <Aside/>
            </div>
            <div className="newtask-body">

              <div className="card">
                <div className="card-header">
                  <h2>Lista de localidades</h2>
                </div>
                <div className="card-body">

                {(this.state.locations.size>0) ? (
                  <ul className="list-group">
                    {this.state.locations.map((v,i) => {
                      return (
                        <li className="d-flex justify-content-between align-items-center list-group-item" key={i}>
                          <p>{v.get('name')} : {v.get('zone')}</p>
                          {buttonEnable ? (
                            <button className="btn btn-danger" onClick={(e)=>this.deleteLocation({i,id:v.get('_id')},e)}>Eliminar</button>
                          ):(
                            <button className="btn btn-danger" disabled>Eliminar</button>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                ):(
                  <ul className="list-group">
                    <li className="d-flex justify-content-between align-items-center list-group-item">no hay localidades</li>
                  </ul>
                )}
                </div>
              </div>
              <NewLocation
                location={this.state.location}
                zone={this.state.zone}
                zones={this.state.zones}
                error={this.state.error}
                setLocation={this.setLocation.bind(this)}
                handleChangeZone={this.handleChangeZone.bind(this)}
                buttonEnable={this.state.buttonEnable}
                handleSubmit={this.handleSubmit.bind(this)}
              />
            </div>
          </main>
      );
    }

  }
}

export default Locations;
