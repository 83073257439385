import React,{Component} from 'react';
import {  endpointSound  } from '../../config';
import Aside from '../../components/Aside';

import TracertContext from '../../context/app-context';

export default class UploadAudio extends Component {

    static contextType = TracertContext;

    constructor(props) {
      super(props);
        this.state = {
          selectedFile: null,
          error: null,
          uploading: false
        }
     
    }

    onClickHandler = () => {  
        let data = new FormData()
        this.setState({uploading:true});
         data.append('name',`01`);
         data.append('file', this.state.selectedFile);
         fetch(`${endpointSound}/upsound`, {
          method: 'POST',
          body: data
        })
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if(response){
              this.setState({uploading:false})
            }else{
              this.setState({error:"Error subiendo la imagen"})
            }
        });
      
    }

    onChangeHandler=event=>{
        //console.log(event.target.files[0].type);
        if(event.target.files[0].type!=="audio/mpeg"){
         this.setState({
           error: "Debes seleccionar un archivo de audio con formato mp3."
         })
        }else if((event.target.files[0].size/1048576)>=11){
          this.setState({
           error: "El peso del archivo de audio debe ser inferior o igual a 10MiB"
          })
        }else{
         this.setState({
           error: null,
           selectedFile: event.target.files[0],
           loaded: 0,
         })
        }
     }

    renderButton(selectedFile,error,uploading){
        if(selectedFile===null){
          return <button type="button" className="btn btn-success btn-block" disabled>Subir audio</button>;
        }else if(uploading===true){
          return <button type="button" className="btn btn-success btn-block" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Subiendo ...</button>;
        }else if(selectedFile!==null&&uploading===false){
          return <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler} >Subir audio</button>;
        }
    }

    render(){
        const { error, uploading, selectedFile } = this.state;
        return(
            <main className="newtask">
                <div className="newtask-aside">
                    <Aside/>
                </div>
                <div className="newtask-body">
                    <div className="card">
                        <div className="card-header">
                            <h2>Subida de audio</h2>
                        </div>
                        <div className="card-body">
                        <h4>El archivo de audio a subir puede pesar hasta 10MiB y su formato debe ser mp3.</h4>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlFile1">Seleccione un archivo de audio</label>
                            {(!uploading) ? (<input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.onChangeHandler}/>):(<input type="file" className="form-control-file" id="exampleFormControlFile1" disabled/>)}
                        </div>
                        {this.renderButton(selectedFile,error,uploading)}
                        {error ? <div className="alert alert-danger mt-2">{error}</div> : null } 
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}