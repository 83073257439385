import React from 'react';
import { Link } from 'react-router-dom';
import BtnSignout from './BtnSignout';
import logo from '../images/tracert.svg';
import {toHome} from '../config';
import logouser from '../images/user-circle-solid.svg';

import Alarm from './Alarm';

function Header(props){
	let role = localStorage.getItem('role');
	if (role==='admin'||role==='root'){
		return (
	<nav className="d-flex justify-content-between navbar-light bg-light p-2" style={{"flexWrap":"wrap"}}>
	  <div className="navbar-brand">

	  	<div className="logo">
			<img alt="logo" src={logo} className="logo-img"/>
			<Link to={toHome} className="link text-dark" style={{"fontFamily": "Gugi","fontSize":"1em"}}>Tracert</Link>
		</div>
	  
	  </div>
	  

	  <div>
	    <div className="form-inline my-2 my-lg-0">
			<Alarm/>

	      <div className="link">{localStorage.getItem('email')}</div>
			<img className="logouser" src={logouser} height={30} width={30} alt="logo"/>
			<div className="link"><BtnSignout/></div>

			
	    </div>
	  
	  </div>
	</nav>
		);
	}else return null;
}

export default Header;
